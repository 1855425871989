@import '../config/sass/variables';

*,
*:before,
*:after {
    box-sizing: border-box;
    cursor: default;
    user-select: none;
    -webkit-user-select: none;
    outline-offset: 2px;
}
.DEBUG *,
.user-select,
[contenteditable='true'],
[contenteditable='true'] * {
    cursor: text;
    user-select: auto;
    -webkit-user-select: text;
}

[contenteditable]:empty:before {
    content: '\feff';
}

body {
    margin: 0;
    font-family: $cc-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $cc-font-size;
    overflow-anchor: none;
}

body * {
    margin: 0;
}

a {
    color: $cc-text-color;
    text-decoration: none;
}

code {
    font-family: $cc-font-family-monospace;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

#root {
    background-color: $cc-app-background-color;
    color: $cc-text-color;
}
/*
@media (prefers-color-scheme: dark) {
    #root {
        background-color: $cc-app-background-color-dark;
        color: $cc-text-color-dark;
    }
}
*/

.av-Divider,
.av-Splitter {
    background-color: $cc-divider;
}
@media (prefers-color-scheme: dark) {
    .av-Divider,
    .av-Splitter {
        background-color: $cc-divider-dark;
    }
}

.avatar,
.projecticon {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid $cc-divider;
    border-radius: 5px;
    background-color: $cc-white;
}
.avatar > img,
.projecticon > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    border: 1px solid $cc-white;
    background-color: $cc-white;
}

.header {
    background-color: $cc-lightgray4;
    padding: $cc-pad2;
}
.header .info {
    height: 34px;
    line-height: 34px;
}
.header .info .project-name {
    font-weight: 600;
    margin: 2px 10px 2px 2px;
}
.header .info .project-name .cc-Bar.cc-raised.projecticon {
    border-radius: 2px;
    width: 30px;
    height: 30px;
}
.header .last-saved {
    font-size: $cc-font-size2;
    margin: 0 $cc-margin1 0 $cc-margin3;
    position: relative;
    top: 1px;
}
.header .cc-Bar.cc-raised.avatar {
    width: 36px;
    height: 36px;
}
.header .cc-Bar.cc-raised.cc-selected {
    border-color: #70aad8;
}
.header .cc-Bar .cc-Button {
    width: auto;
}
.header .info .cc-Bar .cc-Button {
    margin: 0 2px;
}
.header .avatar,
.header .avatar > img {
    border-radius: 50%;
}

.form {
    width: 400px;
}
.form .link {
    text-decoration: underline;
    text-decoration-color: $cc-blue2;
}
.form .cc-Property-Label {
    width: 140px;
    padding-right: 20px;
}
.form .cc-Bar.cc-raised .cc-Button.cc-action {
    background: $cc-lightblue3;
}
.form .cc-Bar.cc-raised .cc-Button.cc-action.cc-disabled {
    background: $cc-lightgray5;
}

.form .cc-Input,
.form .cc-Select {
}
.form .cc-Input.cc-disabled,
.form .cc-Select.cc-disabled {
    background-color: $cc-lightgray5;
}
.form .cc-Input.validation-error,
.form .cc-Select.validation-error {
    background-color: $cc-lightred5;
}
.form .cc-Input.validation-report,
.form .cc-Select.validation-report {
    background-color: $cc-lightorange5;
}

.ws-record-navigator .cc-disabled {
    color: $cc-text-color;
}

.projectsscreen .user-panel {
    width: 600px;
}

.user-panel a {
    display: block;
}
.user-panel .project:hover {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}
.user-panel .project:hover a {
    color: $cc-selection-text-color;
}

.message-panel {
    min-width: 320px;
    max-width: 560px;
}

.navigator,
.inspector,
.toolbar,
.statusbar {
    background-color: $cc-lightgray5;
}
.cc-Popover .inspector:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}
.cc-Popover .inspector:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}
.inspector.cc-danger {
    background-color: $cc-danger-background-color;
    color: $cc-danger-text-color;
}

.toolbar {
    line-height: 28px;
    padding: 0 $cc-pad2;
    position: relative;
}
.toolbar > .cc-Button {
    background-color: transparent;
}

.toolbar > .breadcrumbs-separator {
    margin: 0 $cc-pad2;
    color: $cc-gray2;
}

.statusbar {
    padding: $cc-pad2;
    align-items: center;
}
.statusbar .cc-Button.cc-with-icon {
    padding: 3px;
}

.statusbar .cc-Property {
    margin-bottom: 0;
    margin-right: $cc-pad3;
}
.statusbar .cc-Property .cc-Property-Label {
    width: auto;
    padding-left: 0;
}
.statusbar .cc-Property .cc-Property-Value {
    max-width: none;
}

.worksheet {
    padding: $cc-pad4;
    min-height: calc(100% - 2 * $cc-pad4);
    width: 100%;
    min-width: 600px;
    max-width: 860px;
    margin: 0 auto;
}

.bg-info {
    background-color: $cc-info-background-color !important;
}
.bg-ok {
    background-color: $cc-ok-background-color !important;
}
.bg-notice {
    background-color: $cc-notice-background-color !important;
}
.bg-danger {
    background-color: $cc-danger-background-color !important;
}

.cc-Popover .panel {
    padding: $cc-pad3;
}

.cc-Popover .about-panel {
    width: 360px;
}
.about-panel .avatar {
    width: 120px;
    height: 120px;
    border: 0;
}

.cc-Popover .user-panel {
    width: 360px;
}
.user-panel .avatar {
    width: 80px;
    height: 80px;
    font-size: 30px;
    border: 1px solid $cc-divider;
}
.user-panel .avatar > img {
    border-radius: 6px;
    border-width: 4px;
}

.cc-Popover .project-selection-panel {
    min-width: 220px;
    max-width: 500px;
    padding: $cc-pad2 0;
}
.project-selection-panel > .av-VScrollView .project {
    height: 50px;
    padding: 5px;
}
.project-selection-panel > .av-VScrollView .project .project-name {
    margin-right: 15px;
    font-weight: 600;
    font-size: 1.2em;
}
.project-selection-panel > .av-VScrollView .project .role {
    font-size: $cc-font-size2;
    padding: $cc-pad1 $cc-pad2;
    border-radius: $cc-pad1;
    border: 1px solid white;
    position: relative;
}

.cc-Popover .cc-Menu {
    max-width: 200px;
    padding: $cc-pad2 0;
}

.cc-Popover .layouttreemenu-panel {
    width: 240px;
}
.cc-Popover .layouttreemenu-panel .cc-Menu {
    max-width: none;
}
.cc-Popover .fieldcontextmenu-panel {
    width: 320px;
}
.cc-Popover .fieldcontextmenu-panel .cc-Menu {
    max-width: none;
}
.cc-Popover .selectedasset-panel .cc-Menu {
    max-width: none;
}

.cc-Popover .querymenu-panel {
    width: 240px;
}
.cc-Popover .querymenu-panel .cc-Menu {
    max-width: none;
}
.filter-search-panel .cc-SearchInput .cc-Input > div {
    padding-right: 22px;
}

.inline-actions {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
}
.inline-actions .cc-Button {
    padding: $cc-pad1;
    border: 1px solid $cc-gray3;
    border-radius: 15px;
    overflow: hidden;
}
.inline-actions .cc-Button.bg-color-cc-danger {
    border-color: $cc-danger-background-color;
}
.inline-actions .cc-Button.bg-color-cc-notice {
    border-color: $cc-notice-background-color;
}
.inline-actions .cc-Button.bg-color-cc-ok {
    border-color: $cc-ok-background-color;
}
.inline-actions .cc-Button.bg-color-cc-info {
    border-color: $cc-info-background-color;
}
.inline-actions .cc-Button.cc-minimal {
    border: 0;
    background-color: transparent;
}
.pinboard-panel .inline-actions .cc-Button {
    border: 0;
    background-color: transparent;
}
.pinboard-panel .cc-selected {
    background-color: $cc-lightblue3;
}

.toolbar .inline-actions {
    top: $cc-pad2;
    right: $cc-pad2;
}

.cc-TreeItem .inline-actions {
    top: 1px;
    right: $cc-pad1;
}
.cc-Property .inline-actions {
    top: 1px;
    right: $cc-pad2;
}
.panel.properties .cc-TreeItem .inline-actions {
    top: 1px;
    right: $cc-pad2;
}

.actions-panel {
    width: 200px;
    overflow-x: hidden;
}
.actions-panel .cc-Bar {
    margin-bottom: $cc-pad2;
}
.actions-panel .cc-Bar:last-child {
    margin-bottom: 0;
}
.actions-panel .cc-Bar svg {
    position: relative;
    top: 0.11em;
}
.movecopylink-actions-panel {
    width: 140px;
}

.actions-panel.change-email-panel,
.actions-panel.change-email-panel .cc-Menu {
    width: 320px;
    max-width: 320px;
}

.asset-pinboard-panel .cc-TreeItem .cc-TreeItem-Icon {
    position: relative;
    top: 2px;
}
.schema-pinboard-panel .cc-TreeItem .cc-TreeItem-Icon {
    position: relative;
    top: 2px;
}

.pinboard-drop {
    position: relative;
}
.pinboard-drop.drag-over-inside::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 2px solid $cc-blue3;
}
.pinboard-drop.drag-over-inside.drop-rejected::before {
    border-color: $cc-orange4;
}
.pinboard-drop.drag-over-inside .cc-TreeItem::before {
    background-color: transparent;
}
.pinboardtoggle-drop {
    position: relative;
}
.pinboardtoggle-drop.drag-over::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border: 2px solid $cc-blue3;
    z-index: 1;
}
.pinboardtoggle-drop.drag-over.drop-rejected::before {
    border-color: $cc-orange4;
}
.pinboardtoggle-drop.pinboard-full .cc-Button:not(.cc-disabled) {
    color: $cc-orange2;
}
.pinboardtoggle-drop.pinboard-full .cc-Button:not(.cc-disabled) path {
    stroke: $cc-orange2;
}
.pinboardtoggle-drop.pinboard-full .cc-Button:not(.cc-disabled) path.cc-fill {
    fill: $cc-orange2;
}

.dropzone {
    position: relative;
}
.dropzone.drag-over-top::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-top: 2px solid $cc-blue3;
}
.dropzone.drag-over-bottom::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-bottom: 2px solid $cc-blue3;
}
.dropzone .draghandle {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.background-squares {
    line-height: 0.01em;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25'%3E%3Cdefs%3E%3Cpattern id='transparency-squares' x='0' y='0' width='20' height='20' patternUnits='userSpaceOnUse'%3E%3Crect fill='%23eee' x='0' y='0' width='10' height='10'%3E%3C/rect %3E%3Crect fill='%23eee' x='10' y='10' width='10' height='10'%3E%3C/rect %3E%3C/pattern%3E%3C/defs%3E%3Crect x='0' y='0' width='100%25' height='100%25' fill='url(%23transparency-squares)'%3E%3C/rect%3E%3C/svg%3E");
}
.component {
    background-color: rgba(255, 0, 0, 0.05);
}
.badge {
    font-size: 0.8em;
    padding: $cc-pad1;
    white-space: nowrap;
    background-color: $cc-white;
    opacity: 0.8;
    min-width: 6px;
    min-height: 6px;
    border-radius: 50px;
}
.badge.cc-info {
    background-color: $cc-info-border-color;
}
.badge.cc-ok {
    background-color: $cc-ok-border-color;
}
.badge.cc-notice {
    background-color: $cc-notice-border-color;
}
.badge.cc-danger {
    background-color: $cc-danger-border-color;
}
.member .badge {
    min-width: 8px;
    min-height: 8px;
}
.av-LayerView > .av-Layer.av-anchor-top-end.av-edge.badge {
    transform: translate(20%, -40%);
}

.dam-folder-info.badge {
    transform: translate(-3px, -12px) !important;
    opacity: 1;
    padding: $cc-pad1 $cc-pad2;
    border: 1px solid $cc-text-color;
}
.dam-worksheet-upload .ws-tile {
    padding: $cc-pad2;
    border-radius: 3px;
}

.cc-Bar.bg-color-cc-danger .cc-Button:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 22px;
    background-color: $cc-danger-background-color;
}
.cc-Bar.bg-color-cc-notice .cc-Button:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 22px;
    background-color: $cc-notice-background-color;
}
.cc-Bar.bg-color-cc-ok .cc-Button:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 22px;
    background-color: $cc-ok-background-color;
}
.cc-Bar.bg-color-cc-info .cc-Button:before {
    content: '';
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    width: 22px;
    background-color: $cc-info-background-color;
}

.cc-Property.cc-packed {
    margin-bottom: 0;
}

.cc-TreeItem {
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
}
.cc-TreeItem.channel-off {
    color: $cc-gray2;
}
.cc-TreeItem.status-created {
    border-right: 4px solid $cc-green3;
}
.cc-TreeItem.status-deleted {
    color: $cc-red2;
    text-decoration: line-through $cc-red1;
}
.cc-TreeItem.status-modified {
    border-right: 4px solid $cc-blue3;
}

.cc-Item {
    border-right: 4px solid transparent;
}
.cc-Item.status-created {
    border-right: 4px solid $cc-green3;
}
.cc-Item.status-deleted {
    color: $cc-red2;
    text-decoration: line-through $cc-red1;
}
.cc-Item.status-modified {
    border-right: 4px solid $cc-blue3;
}

.workspace-pim .cc-TreeItem.status-created,
#portal .cc-TreeItem.status-created {
    border-right: 4px solid transparent;
}
.workspace-pim .cc-TreeItem.status-modified,
#portal .cc-TreeItem.status-modified {
    border-right: 4px solid transparent;
}
.workspace-pim .cc-Item.status-created,
#portal .cc-Item.status-created {
    border-right: 4px solid transparent;
}
.workspace-pim .cc-Item.status-modified,
#portal .cc-Item.status-modified {
    border-right: 4px solid transparent;
}

.cc-Text.status-modified::before,
.cc-TreeItem-name.status-modified::before,
.cc-TreeItem-name.status-is-recursive::before {
    display: inline-block;
    content: '';
    line-height: 1em;
    margin-right: 5px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: $cc-blue3;
}
.cc-TreeItem-name.status-modified::before {
    margin-right: 3px;
}
.cc-TreeItem-name.status-is-recursive::before {
    margin-right: 3px;
    background-color: $cc-red3;
}
.cc-Text.status-created::before {
    display: inline-block;
    content: '';
    line-height: 1em;
    margin-right: 5px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: $cc-green3;
}
.cc-Text.status-deleted::before {
    display: inline-block;
    content: '';
    line-height: 1em;
    margin-right: 5px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: $cc-red3;
}
.cc-inline.status-modified::before {
    display: inline-block;
    content: '';
    line-height: 1em;
    margin-right: 5px;
    width: 7px;
    height: 7px;
    border-radius: 5px;
    background-color: $cc-blue3;
}
.status-modified.status-is-new::before {
    background-color: $cc-green3;
}
.status-modified.status-has-modified::before {
    background-color: $cc-orange2;
}

.user-panel .cc-Text.status-deleted::before {
    display: none;
}
.user-panel .cc-Text.status-deleted {
    color: $cc-red2;
    text-decoration: line-through $cc-red1;
}

.panel.properties .status-modified svg path {
    stroke: $cc_blue2;
}
.panel.properties .status-modified svg path.cc-fill {
    fill: $cc_blue2;
}
.panel.properties .status-new svg path {
    stroke: $cc_green2;
}
.panel.properties .status-new svg path.cc-fill {
    fill: $cc_green2;
}

.dam-preview-file {
    line-height: 0;
    box-shadow: 0 0 4px 0px $cc-divider, 0 10px 6px -6px $cc-divider;
}

.dam-preview-container {
    position: relative;
    height: 0;
    padding-bottom: 100%;
}
.dam-preview {
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.dam-preview .cc-Image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: scale-down;
}
.dam-preview .image-area {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    line-height: 0;
    box-shadow: 0 0 4px 0px $cc-divider, 0 10px 6px -6px $cc-divider;
}
.dam-preview .transform-hotspot {
    position: absolute;
    top: calc(50% - 4px);
    left: calc(50% - 4px);
    width: 8px;
    height: 8px;
    border: 1px solid $cc-blue5;
    border-radius: 8px;
    box-shadow: 0 0 0 1px $cc-blue1, inset 0 0 0 1px $cc-blue1;
}
.dam-preview .transform-crop {
    position: absolute;
    top: 20%;
    left: 20%;
    width: 60%;
    height: 60%;
    border: 1px solid $cc-blue5;
    box-shadow: 0 0 0 1px $cc-blue1, inset 0 0 0 1px $cc-blue1;
}

.dam-transform-container {
    position: relative;
    height: 0;
    padding-bottom: 100%;
}
.dam-transform {
    line-height: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 4px 0px $cc-divider, 0 10px 6px -6px $cc-divider;
}
.dam-transform .cc-Image {
    height: 100%;
    width: 100%;
}

.dam-asset-upload {
    border: 2px dashed $cc-lightgray3;
    margin: 5px 10px;
    padding: 40px 20px;
    text-align: center;
    background: white;
}
.dam-asset-upload.drag-over-inside {
    border: 2px solid $cc-blue3;
}
.dam-asset-upload.asset-upload-failed {
    border-color: $cc-danger-border-color;
}
.dam-asset-upload.asset-upload-failed .cc-Header {
    color: $cc-danger-text-color;
}

.dam-worksheet-upload {
    border: 1px solid $cc-white;
}
.dam-worksheet-upload.drag-over-inside {
    border-color: $cc-blue3;
}

.change-password-panel .avatar-upload,
.change-password-panel .projecticon-upload {
    border: 2px dashed $cc-lightgray3;
    margin: 5px 10px 10px;
    padding: 40px 20px;
    text-align: center;
    background: white;
}
.change-password-panel .avatar-upload.drag-over-inside,
.change-password-panel .projecticon-upload.drag-over-inside {
    border: 2px solid $cc-blue3;
}

.transform-testing-options .cc-Property {
    margin-bottom: 0;
    margin-right: $cc-pad3;
}
.transform-testing-options .cc-Property .cc-Property-Label {
    width: auto;
    padding-left: 0;
}
.transform-testing-options .cc-Property .cc-Property-Value {
    max-width: none;
}
.transform-testing-options .cc-Property .cc-Select {
    min-width: 70px;
    border: 0;
}

.filter-action .cc-Text,
.filter-action .cc-Input {
    margin-right: 20px;
    min-height: 19px;
}

.cc-inline .cc-Text,
.cc-inline .cc-Input {
    display: inline-block;
    width: calc(100% - 40px);
    padding: 0;
}

.dropitem {
    position: relative;
}
.dropitem.drag-over-inside,
.dropitem.drag-over-top,
.dropitem.drag-over-bottom,
.dropitem.drag-over-left,
.dropitem.drag-over-right {
    background-color: $cc-lightblue3;
}
.dropitem.drag-over-inside::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $cc-blue4;
    z-index: -1;
}
.dropitem.drag-over-top::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 2px;
    background-color: $cc-blue3;
}
.dropitem.drag-over-left::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    width: 2px;
    top: 0;
    bottom: 0;
    background-color: $cc-blue3;
}
.dropitem.drag-over-bottom::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: $cc-blue3;
}
.dropitem.drag-over-right::before {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    width: 2px;
    top: 0;
    bottom: 0;
    background-color: $cc-blue3;
}

.droprow {
    position: relative;
}
.droprow.drag-over-inside,
.droprow.drag-over-top,
.droprow.drag-over-bottom,
.droprow.drag-over-left,
.droprow.drag-over-right {
    background-color: $cc-lightblue3;
}
.droprow.drag-over-inside {
    background-color: $cc-blue4;
}
.droprow.drag-over-top {
    border-top: 2px solid $cc-blue3 !important;
}
.droprow.drag-over-left {
    border-left: 2px solid $cc-blue3 !important;
}
.droprow.drag-over-bottom {
    border-bottom: 2px solid $cc-blue3 !important;
}
.droprow.drag-over-right {
    border-right: 2px solid $cc-blue3 !important;
}

.bg-color-cc-danger {
    background-color: $cc-danger-background-color;
}
.bg-color-cc-notice {
    background-color: $cc-notice-background-color;
}
.bg-color-cc-ok {
    background-color: $cc-ok-background-color;
}
.bg-color-cc-info {
    background-color: $cc-info-background-color;
}

.cc-FilterItem .filter-part {
    margin-left: $cc-pad2;
    padding: 0;
    background-color: $cc-lightgray4;
    border-radius: $cc-pad1;
}
.cc-FilterItem .filter-part.filter-property {
    margin-left: 0;
}
.cc-FilterItem .filter-operand-input {
    background-color: $cc-white;
}
.cc-FilterItem .filter-logic {
    background-color: transparent;
}

.cc-FilterItem.cc-selected {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}
.cc-FilterItem.cc-selected .filter-part {
    background-color: $cc-blue4;
}
.cc-FilterItem.cc-selected .filter-operand-input,
.cc-FilterItem.cc-selected .cc-Input {
    background-color: $cc-lightblue2;
}
.cc-FilterItem.cc-selected .filter-logic {
    background-color: transparent;
}

.maybe-start-dragging .cc-FilterItem .filter-part,
.maybe-start-dragging .cc-FilterItem.cc-selected .filter-part,
.maybe-start-dragging .cc-FilterItem.cc-selected .filter-operand-input,
.maybe-start-dragging .cc-FilterItem.cc-selected .cc-Input,
.maybe-start-dragging .cc-FilterItem.cc-selected .filter-logic {
    background-color: transparent;
}

.cc-FilterItem .filter-part .cc-Text {
    padding: $cc-pad1 $cc-pad2;
}
.cc-FilterItem .filter-part .cc-Select {
    min-width: 0px;
    padding: $cc-pad1 $cc-pad2;
    padding-right: 22px;
    background-color: transparent;
    border-color: transparent;
}
.cc-FilterItem .filter-part .cc-Input {
    height: 100%;
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
    min-width: 3em;
}
.cc-FilterItem .filter-line {
    min-height: 23px;
}
.cc-FilterItem .filter-line.compact .cc-Placeholder-inline {
    padding: 5px 0 0;
}
.cc-FilterItem .filter-line.compact .cc-Icon {
    position: relative;
    top: 3px;
}
.cc-FilterItem .filter-line.compact .cc-Select-Icon {
    position: absolute;
    top: 2px;
}
.cc-FilterItem .filter-line.compact.filter-add-filter .cc-Icon {
    top: 1px;
}
.cc-FilterItem .filter-part.filter-icon {
    padding: $cc-pad1 $cc-pad2;
}
.cc-FilterItem .filter-part.filter-icon .cc-Icon {
    position: relative;
    top: 2px;
}

.cc-Filter {
    padding: 0 $cc-pad2;
}
.cc-Filter .cc-FilterItem.cc-indent1 {
    padding-left: $cc-pad5;
}
.cc-Filter .cc-FilterItem .filter-logic {
    margin-right: 0;
    position: relative;
    top: 0;
}

.cc-ProjectMember {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding-left: $cc-pad2;
}
