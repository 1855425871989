/* ---- override these for your own theme, just copy/paste/adapt in your own css ---- */
/* ----------------------- don't change these defaults please ----------------------- */

.av-Divider,
.av-Splitter {
    background-color: #eee;
}
@media (prefers-color-scheme: dark) {
    .av-Divider,
    .av-Splitter {
        background-color: #535353;
    }
}

/* ---------------------- never touch anything below this line ---------------------- */

.av-AppView {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex;
}
.av-AppView > div {
    width: 100%;
}

.av-View.av-fill {
    display: flex;
    width: 100%;
    height: 100%;
}
.av-HView {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
}
.av-VView {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
}
.av-View.av-vcenter, .av-HView.av-vcenter , .av-VView.av-vcenter {
    display: flex;
    align-items: center;
}
.av-View.av-hcenter, .av-HView.av-hcenter , .av-VView.av-hcenter {
    display: flex;
    justify-content: center;
}
.av-HView.av-wrap, .av-VView.av-wrap {
    flex-wrap: wrap;
}
.av-HView > div,
.av-VView > div {
    flex: 0 0 auto;
}

div.av-Spacer {
    flex: 1 0 1px;
} /* div added for specificity, so it overrides .HView > div, .VView > div */
div.av-Divider {
    flex: 0 0 auto;
}
.av-HView > .av-Divider {
    width: 1px;
}
.av-VView > .av-Divider {
    height: 1px;
}
div.av-Splitter {
    flex: 0 0 auto;
    position: relative;
}
.av-HView > .av-Splitter {
    width: 1px;
}
.av-HView > .av-Splitter > .av-SplitterHandle {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -4px;
    width: 9px;
    cursor: col-resize;
}
.av-VView > .av-Splitter {
    height: 1px;
}
.av-VView > .av-Splitter > .av-SplitterHandle {
    position: absolute;
    top: -4px;
    height: 9px;
    left: 0;
    right: 0;
    cursor: row-resize;
}

div.av-ScrollView {
    flex: 1 0 1px;
    position: relative;
    overflow: auto;
}
div.av-HScrollView {
    flex: 1 0 1px;
    overflow: hidden;
    overflow-x: auto;
}
div.av-VScrollView {
    flex: 1 0 1px;
    overflow: hidden;
    overflow-y: auto;
}

div.av-LazyScrollItem {
    position: absolute;
    overflow:  hidden;
}
div.av-LazyHScrollList {
    flex: 1 0 1px;
    overflow: hidden;
    overflow-x: auto;
}
div.av-LazyHScrollList > div {
    position: relative;
    height: 100%;
}
div.av-LazyHScrollList > div > div.av-LazyScrollItem {
    top: 0;
    bottom: 0;
}
div.av-LazyVScrollList {
    flex: 1 0 1px;
    overflow: hidden;
    overflow-y: auto;
}
div.av-LazyVScrollList > div {
    position: relative;
    width: 100%;
}
div.av-LazyVScrollList > div > div.av-LazyScrollItem {
    left: 0;
    right: 0;
}

div.av-LazyHScrollTiles {
    flex: 1 0 1px;
    overflow: hidden;
    overflow-x: auto;
}
div.av-LazyHScrollTiles > div {
    position: relative;
    height: 100%;
}

div.av-LazyVScrollTiles {
    flex: 1 0 1px;
    overflow: hidden;
    overflow-y: auto;
}
div.av-LazyVScrollTiles > div {
    position: relative;
    width: 100%;
}

div.av-LazyScrollRow {
}
div.av-LazyVScrollTable {
    flex: 1 0 1px;
    overflow: hidden;
    overflow-y: auto;
}
div.av-LazyVScrollTable > div {
    position: relative;
    width: 100%;
}
div.av-LazyVScrollTable > div > table {
    position: sticky;
    top:  0;
    width: 100%;
}

.av-TilesView {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
}

.av-LayerView {
    position: relative;
}
.av-InlineBlockLayerView {
    display: inline-block;
}
.av-LayerView > div {
    position: relative;
}
.av-LayerView > .av-Layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}
.av-LayerView > .av-Layer.av-anchor-top-start {
    top: 0;
    right: auto;
    bottom: auto;
    left: 0;
}
.av-LayerView > .av-Layer.av-anchor-top {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, 0);
}
.av-LayerView > .av-Layer.av-anchor-top-end {
    top: 0;
    right: 0;
    bottom: auto;
    left: auto;
}
.av-LayerView > .av-Layer.av-anchor-start {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 0;
    transform: translate(0, -50%);
}
.av-LayerView > .av-Layer.av-anchor-center {
    top: 50%;
    right: auto;
    bottom: auto;
    left: 50%;
    transform: translate(-50%, -50%);
}
.av-LayerView > .av-Layer.av-anchor-end {
    top: 50%;
    right: 0;
    bottom: auto;
    left: auto;
    transform: translate(0, -50%);
}
.av-LayerView > .av-Layer.av-anchor-bottom-start {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
}
.av-LayerView > .av-Layer.av-anchor-bottom {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
}
.av-LayerView > .av-Layer.av-anchor-bottom-end {
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-top-start {
    right: 0;
    left: auto;
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-top-end {
    right: auto;
    left: 0;
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-start {
    right: 0;
    left: auto;
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-end {
    right: auto;
    left: 0;
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-bottom-start {
    right: 0;
    left: auto;
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-bottom-end {
    right: auto;
    left: 0;
}

.av-LayerView > .av-Layer.av-anchor-top-start.av-outside {
    transform: translate(-100%, -100%);
}
.av-LayerView > .av-Layer.av-anchor-top.av-outside {
    transform: translate(-50%, -100%);
}
.av-LayerView > .av-Layer.av-anchor-top-end.av-outside {
    transform: translate(100%, -100%);
}
.av-LayerView > .av-Layer.av-anchor-start.av-outside {
    transform: translate(-100%, -50%);
}
.av-LayerView > .av-Layer.av-anchor-end.av-outside {
    transform: translate(100%, -50%);
}
.av-LayerView > .av-Layer.av-anchor-bottom-start.av-outside {
    transform: translate(-100%, 100%);
}
.av-LayerView > .av-Layer.av-anchor-bottom.av-outside {
    transform: translate(-50%, 100%);
}
.av-LayerView > .av-Layer.av-anchor-bottom-end.av-outside {
    transform: translate(100%, 100%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-top-start.av-outside {
    transform: translate(100%, -100%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-top-end.av-outside {
    transform: translate(-100%, -100%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-start.av-outside {
    transform: translate(100%, -50%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-end.av-outside {
    transform: translate(-100%, -50%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-bottom-start.av-outside {
    transform: translate(100%, 100%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-bottom-end.av-outside {
    transform: translate(-100%, 100%);
}

.av-LayerView > .av-Layer.av-anchor-top-start.av-edge {
    transform: translate(-50%, -50%);
}
.av-LayerView > .av-Layer.av-anchor-top.av-edge {
    transform: translate(-50%, -50%);
}
.av-LayerView > .av-Layer.av-anchor-top-end.av-edge {
    transform: translate(50%, -50%);
}
.av-LayerView > .av-Layer.av-anchor-start.av-edge {
    transform: translate(-50%, -50%);
}
.av-LayerView > .av-Layer.av-anchor-end.av-edge {
    transform: translate(50%, -50%);
}
.av-LayerView > .av-Layer.av-anchor-bottom-start.av-edge {
    transform: translate(-50%, 50%);
}
.av-LayerView > .av-Layer.av-anchor-bottom.av-edge {
    transform: translate(-50%, 50%);
}
.av-LayerView > .av-Layer.av-anchor-bottom-end.av-edge {
    transform: translate(50%, 50%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-top-start.av-edge {
    transform: translate(50%, -50%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-top-end.av-edge {
    transform: translate(-50%, -50%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-start.av-edge {
    transform: translate(50%, -50%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-end.av-edge {
    transform: translate(-50%, -50%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-bottom-start.av-edge {
    transform: translate(50%, 50%);
}
[dir='rtl'] .av-LayerView > .av-Layer.av-anchor-bottom-end.av-edge {
    transform: translate(-50%, 50%);
}
