@import '../config/sass/variables';

.site {
    background-color: $cc-lightgray4;
}

.site > div {
    padding: 60px;
    width: 600px;
    margin: 40px auto;
    background-color: white;
    border-radius: 30px;
}

.site div.avatar {
    width: 160px;
    margin: 0 auto 40px auto;
    border: 0;
}

.site h1 {
    text-align: center;
    margin-bottom: 1em;
}

.site hr {
    border: 0;
    border-bottom: 1px solid $cc-lightgray2;
}

.site header {
    font-weight: 600;
}
.site p {
    margin-bottom: 1em;
}

.site .userlink {
    text-align: center;
    color: $cc-text-color-disabled;
    font-size: 1rem;
    margin-bottom: 1em;
}

.site .userlink span {
    color: $cc-blue2;
    cursor: pointer;
}

.site .unicat-site-panel {
}

.site .unicat-site-panel .avatar {
    width: 80px;
    margin: 0;
}

.site .unicat-site-panel .cc-Header {
    padding: 0;
    font-size: 2em;
    font-weight: bold;
}

.site .cc-Input {
    border: 1px solid $cc-blue4;
    border-radius: 3px;
}

.site .cc-Bar.cc-raised:not(:has(.cc-Button.cc-disabled)) {
    border-color: $cc-blue4;
}
.site .cc-Bar.cc-raised .cc-Button:not(.cc-disabled) {
    border-color: $cc-blue4;
}
.site .cc-ValidationMessage {
    padding: 5px 5px 7px;
    margin-top: 3px;
    border-radius: 3px;
}
