@import '../../../../config/sass/variables';

.layout-component {
    position: relative;
}
.layout-component.lc-fallback {
    overflow: scroll;
    padding: 10px;
    background: $cc-orange5;
    border: 1px solid $cc-orange2;
}
.layout-component.lc-recursion {
    overflow: scroll;
    padding: 10px;
    background: $cc-red5;
    border: 1px solid $cc-red2;
}

.layout-component > div.layout-component {
    margin-right: 15px !important;
    margin-bottom: 10px !important;
    min-width: 0;
}
.layout-component > div.layout-component:last-child {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}
.layout-component.av-VView > div {
    margin-right: 0 !important;
}
.layout-component.av-HView > div {
    margin-bottom: 0 !important;
}
.layout-component > .av-TilesView {
    gap: 10px;
}
.layout-component > .av-TilesView > div {
    margin-right: 0 !important;
    margin-bottom: 0 !important;
}

.layout-component.lc-title.ws-text,
.layout-component.lc-title .ws-text {
    font-size: 40px;
    font-weight: 600;
}
.layout-component.lc-subtitle.ws-text,
.layout-component.lc-subtitle .ws-text {
    font-size: $cc-font-size * 2;
    font-weight: 600;
}
.layout-component.ws-text,
.layout-component .ws-text {
    font-size: 14px;
}
.layout-component.lc-bold.ws-text,
.layout-component.lc-bold .ws-text {
    font-weight: 600;
}
.layout-component.lc-note.ws-text,
.layout-component.lc-note .ws-text {
    font-size: 12px;
}
.layout-component.lc-code.ws-text,
.layout-component.lc-code .ws-text {
    font-family: $cc-font-family-monospace;
    font-size: 12px;
    white-space: pre-wrap;
}
.layout-component img {
    width: 100%;
}
.layout-component.lc-image {
    width: 250px;
}
.layout-component.lc-imagelist {
    width: 250px;
}
.layout-component.lc-thumb img {
    width: 80px;
}
.ws-field.layout-component.lc-field-image {
    min-width: auto;
}
.layout-component.av-TilesView .ws-field {
    min-width: auto;
}

.layout-component.cc-selected {
    background-color: inherit;
    outline: 1px solid $cc-blue4;
    outline-offset: 0px;
    z-index: 2;
}
.drag-over-inside .layout-remove-component {
    background-color: $cc-danger-background-color;
}

.layout-tree {
    min-width: 160px;
}
.layout-preview > .layout-component {
    padding: $cc-pad2;
    background-color: $cc-lightgray5;
}
.layout-preview .ws-layoutpreview {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding: 3px;
    border: 1px solid $cc-lightgray3;
    background-color: $cc-white;
}
.layout-preview .ws-layoutpreview.ws-field-class,
.layout-preview .ws-layoutpreview.ws-field-classlist {
    background: transparent;
}
.layout-preview .ws-layoutpreview .ws-sublayoutpreview {
    margin-top: 4px;
}
.layout-preview .ws-table-cell .ws-layoutpreview {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
    padding: 2px;
    border: 0px solid $cc-lightgray3;
    background-color: $cc-white;
}
.layout-preview .layout-component.lc-fallback {
    padding: 4px;
}
.layout-preview .layout-component.lc-recursion {
    padding: 4px;
}
.layout-preview .layout-component > div.layout-component {
    margin-right: 4px !important;
    margin-bottom: 4px !important;
}
.layout-preview .layout-component > div.layout-component:last-child {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
}
.layout-preview .layout-component.av-VView > div.layout-component {
    margin-right: 0 !important;
}
.layout-preview .layout-component.av-HView > div.layout-component {
    margin-bottom: 0 !important;
}
.layout-preview .layout-component > .av-TilesView {
    gap: 4px;
}
.layout-preview .layout-component.lc-title.ws-text,
.layout-preview .layout-component.lc-title .ws-text {
    font-size: 18px;
    font-weight: 600;
}
.layout-preview .layout-component.lc-subtitle.ws-text,
.layout-preview .layout-component.lc-subtitle .ws-text {
    font-size: 14px;
    font-weight: 600;
}
.layout-preview .layout-component.ws-text,
.layout-preview .layout-component .ws-text {
    font-size: 12px;
}
.layout-preview .layout-component.lc-note.ws-text,
.layout-preview .layout-component.lc-note .ws-text {
    font-size: 10px;
}
.layout-preview .layout-component.lc-code.ws-text,
.layout-preview .layout-component.lc-code .ws-text {
    font-size: 12px;
}
.layout-preview .layout-component.lc-image {
    width: 125px;
    height: 125px;
}
.layout-preview .layout-component.lc-imagelist {
    width: 125px;
    height: 40px;
}
.layout-preview .layout-component.lc-thumb img {
    width: 40px;
}
.layout-preview .layout-component .ws-layoutpreview.ws-field-image {
    xheight: 125px;
}
.layout-preview .layout-component .ws-layoutpreview .cc-Icon.cc-icon-sizetext {
    margin-left: 4px;
    position: relative;
    top: 1px;
}
.layout-preview .layout-component .ws-tile {
    padding: 0;
    border: 0;
}
.layout-preview .layout-component .ws-tile > div.layout-component {
    margin-right: 4px !important;
    margin-bottom: 4px !important;
}
.layout-preview .layout-component .ws-tile > div.layout-component:last-child {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
}
.layout-preview .layout-component.av-VView .ws-tile > div.layout-component {
    margin-right: 0 !important;
}
.layout-preview .layout-component.av-HView .ws-tile > div.layout-component {
    margin-bottom: 0 !important;
}

.layout-preview .layout-component .ws-layoutpreview.ws-field-text,
.layout-preview .layout-component .ws-layoutpreview.ws-field-textlist,
.layout-preview .layout-component .ws-layoutpreview.ws-field-code {
    min-height: 2.5em;
}
.layout-preview .layout-component .ws-tile .ws-layoutpreview.ws-field-image {
    min-height: 80px;
}
.layout-preview .layout-component .ws-layoutpreview.ws-field-imagelist,
.layout-preview .layout-component .ws-layoutpreview.ws-field-filelist,
.layout-preview .layout-component .ws-layoutpreview.ws-field-recordlist,
.layout-preview .layout-component .ws-layoutpreview.ws-field-classlist {
    min-height: 40px;
}
.ws-preview-field.ws-preview-card {
}
.ws-preview-field.ws-preview-item {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.ws-preview-field.ws-preview-item .ws-preview-item-icon {
    width: 24px;
}
.ws-preview-field.ws-preview-item .ws-preview-item-text {
    width: calc(100% - 29px);
    margin-left: 5px;
}
.ws-preview-field.ws-preview-card .ws-preview-item-properties {
    position: relative;
    top: -10px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}
.ws-preview-field.ws-preview-card .ws-preview-item-properties .ws-preview-item-labels {
    width: 40%;
}
.ws-preview-field.ws-preview-card .ws-preview-item-properties .ws-preview-item-values {
    width: calc(60% - 2px);
    margin-left: 2px;
}

.ws-preview-field.ws-preview-thumb .ws-preview-item-icon {
    border: 1px solid $cc-lightgray4;
    position: relative;
    width: 100%;
    padding-top: 100%;
    line-height: 0;
    overflow: hidden;
}
.ws-preview-field.ws-preview-thumb .ws-preview-item-icon .cc-Icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-fit_banner .ws-preview-item-icon,
.ws-preview-field.ws-preview-thumb.ws-preview-format-fill_banner .ws-preview-item-icon {
    padding-top: 30%;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-fill_banner
    .ws-preview-item-icon
    .cc-Icon {
    top: -116.67%;
    height: 333.33%;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-fit_landscape
    .ws-preview-item-icon,
.ws-preview-field.ws-preview-thumb.ws-preview-format-fill_landscape
    .ws-preview-item-icon {
    padding-top: 56.25%;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-fill_landscape
    .ws-preview-item-icon
    .cc-Icon {
    top: -38.89%;
    height: 177.78%;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-fit_portrait .ws-preview-item-icon,
.ws-preview-field.ws-preview-thumb.ws-preview-format-fill_portrait
    .ws-preview-item-icon {
    padding-top: 177.78%;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-fill_portrait
    .ws-preview-item-icon
    .cc-Icon {
    left: -38.89%;
    width: 177.78%;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-fit_pillar .ws-preview-item-icon,
.ws-preview-field.ws-preview-thumb.ws-preview-format-fill_pillar .ws-preview-item-icon {
    padding-top: 333.33%;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-fill_pillar
    .ws-preview-item-icon
    .cc-Icon {
    left: -116.67%;
    width: 333.33%;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-proportional
    .ws-preview-item-icon {
    position: relative;
    width: 100%;
    padding-top: 0;
    height: auto;
}
.ws-preview-field.ws-preview-thumb.ws-preview-format-proportional
    .ws-preview-item-icon
    .cc-Icon {
    position: relative;
    width: 100%;
    height: auto;
}

.ws-preview-field > .av-TilesView {
    margin-top: 3px;
}
.ws-preview-text {
    background-color: $cc-lightgray5;
    border: 1px solid $cc-lightgray3;
    line-height: 0.5em;
    margin-bottom: 2px;
}
.ws-preview-text:last-child {
    margin-bottom: 0;
}

.worksheet-account,
.worksheet-settings {
    min-height: 100%;
    min-width: 640px;
    max-width: 640px;
}

.worksheet-account .cc-Property,
.worksheet-settings .cc-Property {
    min-height: calc(1.5em + 16px);
    min-width: 200px;
    position: relative;
    border-left: 1px solid $cc-lightgray4;
    padding-top: 16px;
    margin-bottom: $cc-size4;
    margin-right: $cc-size2;
    display: block;
}
.worksheet-account .cc-Property-Label,
.worksheet-settings .cc-Property-Label {
    position: absolute;
    top: 0;
    left: -1px;
    height: 16px;
    width: auto;
    line-height: 12px;
    padding: $cc-pad1 $cc-pad2;
    background-color: $cc-lightgray5;
    font-size: $cc-font-size2;
    color: $cc-gray1;
}
.worksheet-account .cc-Property-Label > div,
.worksheet-settings .cc-Property-Label > div {
    display: inline-block;
    margin-left: $cc-pad2;
}
.worksheet-account .cc-Property-Value,
.worksheet-settings .cc-Property-Value {
    max-width: none;
}

.worksheet-account .cc-ProjectMember,
.workspace-settings .cc-ProjectMember {
    margin-top: $cc-pad2;
}
.worksheet-account .cc-ProjectMember:last-child,
.workspace-settings .cc-ProjectMember:last-child {
    margin-bottom: $cc-pad2;
}
.worksheet-account .avatar,
.workspace-settings .avatar {
    width: 30px;
    height: 30px;
}
.worksheet-account .role,
.workspace-settings .role {
    margin-left: $cc-pad2;
    font-size: $cc-font-size2;
    padding: $cc-pad1 $cc-pad2;
    background-color: $cc-green5;
    border-radius: $cc-pad1;
    position: relative;
    top: -1px;
}

.worksheet-account .cc-Property-Value .ws-title,
.worksheet-settings .cc-Property-Value .ws-title {
    font-size: $cc-font-size * 2;
    font-weight: 600;
}

.worksheet-account-settings .avatar,
.worksheet-project-settings .projecticon {
    width: 80px;
    height: 80px;
    font-size: 30px;
    border: 1px solid $cc-divider;
}

.worksheet-account-settings .avatar > img,
.worksheet-project-settings .projecticon > img {
    border-radius: 6px;
    border-width: 4px;
}

.worksheet-account-settings .avatar-upload.drag-over-inside > .avatar,
.worksheet-project-settings .projecticon-upload.drag-over-inside > .projecticon {
    border-color: $cc-blue3;
}

.workspace-schema .role {
    margin-left: $cc-pad2;
    font-size: $cc-font-size2;
    padding: $cc-pad1 $cc-pad2;
    background-color: $cc-green5;
    border-radius: $cc-pad1;
    position: relative;
    top: -1px;
}

.workspace-pim .role {
    margin-left: $cc-pad2;
    font-size: $cc-font-size2;
    padding: $cc-pad1 $cc-pad2;
    background-color: $cc-green5;
    border-radius: $cc-pad1;
    position: relative;
    top: -1px;
}

.workspace-pim .extended {
    color: $cc-green5;
}
.cc-extended path {
    stroke: $cc-green3;
}
.cc-extended path.cc-fill {
    fill: $cc-green4;
}

span.cc-danger {
    color: $cc-danger-border-color;
}
span.cc-notice {
    color: $cc-notice-border-color;
}
span.cc-ok {
    color: $cc-ok-border-color;
}
span.cc-info {
    color: $cc-info-border-color;
}

.cc-Icon.cc-disabled path {
    stroke: $cc-text-color-disabled;
}
.cc-Icon.cc-disabled path.cc-fill {
    fill: $cc-text-color-disabled;
}
.cc-Icon.cc-danger:not(.cc-disabled) path {
    stroke: $cc-danger-border-color;
}
.cc-Icon.cc-danger:not(.cc-disabled) path.cc-fill {
    fill: $cc-danger-background-color;
}
.cc-Icon.cc-notice:not(.cc-disabled) path {
    stroke: $cc-notice-border-color;
}
.cc-Icon.cc-notice:not(.cc-disabled) path.cc-fill {
    fill: $cc-notice-background-color;
}
.cc-Icon.cc-ok:not(.cc-disabled) path {
    stroke: $cc-ok-border-color;
}
.cc-Icon.cc-ok:not(.cc-disabled) path.cc-fill {
    fill: $cc-ok-background-color;
}
.cc-Icon.cc-info:not(.cc-disabled) path {
    stroke: $cc-info-border-color;
}
.cc-Icon.cc-info:not(.cc-disabled) path.cc-fill {
    fill: $cc-info-background-color;
}

.ws-tag {
    font-size: $cc-font-size2;
    padding: $cc-pad1 $cc-pad2;
    border-radius: $cc-pad2;
    background-color: $cc-lightgray4;
    color: $cc-text-color;
}
.ws-tag.cc-danger {
    background-color: $cc-danger-background-color;
    color: $cc-danger-text-color;
}
.ws-tag.cc-notice {
    background-color: $cc-notice-background-color;
    color: $cc-notice-text-color;
}
.ws-tag.cc-ok {
    background-color: $cc-ok-background-color;
    color: $cc-ok-text-color;
}
.ws-tag.cc-info {
    background-color: $cc-info-background-color;
    color: $cc-info-text-color;
}

.ws-tagbutton {
    font-size: $cc-font-size2;
    padding: $cc-pad1 $cc-pad2;
    border-radius: $cc-pad2;
    background-color: $cc-lightgray4;
    color: $cc-text-color;
    outline: 1px solid $cc-lightgray1;
    outline-offset: -1px;
}
.ws-tagbutton.cc-selected {
    background-color: $cc-info-background-color;
}

.role.cc-danger,
.cc-Text.cc-danger,
.cc-Header.cc-danger {
    background-color: $cc-danger-background-color;
    color: $cc-danger-text-color;
}
.role.cc-notice,
.cc-Text.cc-notice,
.cc-Header.cc-notice {
    background-color: $cc-notice-background-color;
    color: $cc-notice-text-color;
}
.role.cc-ok,
.cc-Text.cc-ok,
.cc-Header.cc-ok {
    background-color: $cc-ok-background-color;
    color: $cc-ok-text-color;
}
.role.cc-info,
.cc-Text.cc-info,
.cc-Header.cc-info {
    background-color: $cc-info-background-color;
    color: $cc-info-text-color;
}

.ws-section-header {
    background: $cc-lightgray5;
    margin-top: $cc-size2;
    margin-bottom: $cc-size2;
    font-size: 16px;
    font-weight: 600;
    border-bottom: 1px solid $cc-lightgray3;
}
.ws-section-header:first-child {
    margin-top: 0;
}
.ws-section-header .ws-tag {
    position: relative;
    top: -1px;
    font-weight: 400;
}

.ws-field {
    min-height: calc(1.5em + 16px);
    min-width: 150px;
    position: relative;
    border-left: 1px solid $cc-lightgray5;
    padding-top: 16px;
    margin-bottom: $cc-size4;
    margin-right: $cc-size2;
}
.ws-field .ws-inline > div {
    display: inline-block;
}
.ws-label {
    position: absolute;
    top: 0;
    left: -1px;
    height: 16px;
    padding: 0;
    line-height: 16px;
}
.ws-label > div {
    display: inline-block;
    margin-left: $cc-pad2;
}
.ws-label .label {
    height: 16px;
    line-height: 16px;
    padding: $cc-pad1 $cc-pad2;
    background-color: $cc-lightgray5;
    font-size: $cc-font-size2;
    color: $cc-gray1;
}
.ws-label .label > div {
    display: inline-block;
    margin-left: 4px;
}
.ws-label .language,
.ws-table-cell-translation .language {
    margin-left: $cc-pad1;
    padding: 1px 4px;
    background-color: $cc-lightgray5;
    font-size: $cc-font-size2;
    color: $cc-gray1;
    border-radius: $cc-pad1;
    border: 1px solid $cc-lightgray3;
}
.ws-table-cell-translation .language {
    margin-left: $cc-pad2;
    margin-right: 2px;
}
.ws-placeholder {
    display: none;
    padding: $cc-pad1 $cc-pad2;
    z-index: 2;
    position: absolute;
    top: 18px;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    color: $cc-gray5;
    pointer-events: none;
}
.ws-placeholder-with-field > .ws-placeholder {
    position: relative;
    top: 0;
    margin-bottom: 0 !important;
}
.ws-placeholder-visible > .ws-placeholder {
    display: block;
}
.ws-margin-bottom {
    margin-bottom: $cc-size4;
}

.ws-selected.ws-section-header {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}
.ws-selected.ws-field {
    border-left-color: $cc-selection-background-color;
}
.ws-selected.ws-field .ws-label {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}

.ws-selected.ws-field .ws-label .label {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}

.ws-image {
    width: 100%;
}
.ws-table {
    width: 100%;
}
.ws-table-cell {
    text-align: center;
    border: 1px solid $cc-lightgray5;
}
.ws-table-cell .cc-AssetField {
    width: 64px;
    min-height: 64px;
}
.ws-table-header .ws-table-cell {
    background-color: $cc-lightgray5;
    border-bottom: 3px solid $cc-lightgray5;
}
.ws-table-cell .cc-Text {
    padding: $cc-pad1 $cc-pad2;
}
.ws-table-cell-navigator {
    background-color: $cc-lightgray5;
    border-left: 3px solid $cc-lightgray5;
    width: 21px;
}
.ws-selected.ws-table-cell {
    border-bottom: 3px solid $cc-selection-background-color;
}
.ws-selected > .ws-table-cell-navigator {
    border-left: 3px solid $cc-selection-background-color;
}
.status-deleted .ws-table-cell {
    color: $cc-red2;
    text-decoration: line-through $cc-red1;
    opacity: 0.4;
}

.ws-tiles {
    grid-gap: $cc-size4;
}
.ws-tile {
    padding: $cc-size1;
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
    overflow-x: hidden;
}
.worksheet .ws-tiles .ws-tile {
    background-color: $cc-white;
}
.ws-tiles > div > .ws-tile {
    height: 100%;
}
.ws-tiles .ws-tile.status-deleted,
.ws-propertytable-row .ws-tile.status-deleted,
.ws-propertytable-row .ws-tiles .ws-tile.status-deleted {
    color: $cc-red2;
    text-decoration: line-through $cc-red1;
    border: 1px solid $cc-red3;
    opacity: 0.4;
}
.ws-tiles .ws-tile.status-deleted .cc-Input,
.ws-propertytable-row .ws-tile.status-deleted .cc-Input,
.ws-propertytable-row .ws-tiles .ws-tile.status-deleted .cc-Input {
    color: $cc-red2;
    text-decoration: line-through $cc-red1;
}
.ws-tiles .drag-over-inside .ws-tile {
    border: 1px solid $cc-blue3;
}

.ws-tile .ws-text {
    text-align: center;
}

.ws-tile.project .cc-Text .project-name {
    font-size: 1.2em;
}
.ws-tile.project.cc-selected {
    background-color: inherit;
    color: inherit;
}
.ws-tile.project.cc-selected .cc-Text .project-name {
    font-weight: 600;
}

.ws-field .ws-table {
    margin-top: $cc-margin1;
    margin-left: -1px;
}
.ws-field .ws-tiles {
    margin-top: $cc-margin1;
    margin-left: -1px;
}

.ws-propertytable {
    width: 100%;
}
.ws-propertytable-row {
    display: block;
    margin-bottom: 4px;
}
.ws-propertytable-row.cc-selected {
    background-color: transparent;
}
.ws-propertytable-cell {
    display: inline-block;
    text-align: left;
    vertical-align: top;
}
.ws-propertytable-cell-fullwidth {
    display: block;
}
.ws-propertytable-cell-label {
    display: block;
    padding: $cc-pad1 $cc-pad2;
    font-size: $cc-font-size2;
    background-color: transparent;
    color: $cc-text-color;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ws-propertytable-cell-label > div {
    display: inline-block;
    margin-left: 4px;
}
.ws-propertytable-cell-input {
    display: block;
    padding-right: 10px;
}
.ws-propertytable-cell-input .cc-Input {
    max-height: 85px;
    overflow: auto;
}
.ws-propertytable-cell-input .cc-Input.cc-Input-show-options {
    overflow: visible;
}

.ws-propertytable-cell-input .cc-Text {
    padding: 2px 5px;
    max-width: 267px;
    max-height: 85px;
    overflow: auto;
}
.ws-propertytable-cell-input .cc-AssetField {
    background-color: white;
    max-width: 267px;
    overflow: auto;
}
.ws-propertytable-cell-input .cc-AssetListField {
    max-width: 267px;
    overflow: auto;
}
.ws-propertytable-cell-input .cc-AssetListField .ws-tile {
    background-color: white;
}

.cc-ClassListField .dropitem.drag-over-inside > .ws-tile {
    background-color: $cc-blue4;
}
.cc-ClassListField .dropitem.drag-over-top > .ws-tile {
    background-color: $cc-lightblue3;
    border-top-color: $cc-blue3;
    border-top-width: 2px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
.cc-ClassListField .dropitem.drag-over-left > .ws-tile {
    background-color: $cc-lightblue3;
    border-left-color: $cc-blue3;
    border-left-width: 2px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.cc-ClassListField .dropitem.drag-over-bottom > .ws-tile {
    background-color: $cc-lightblue3;
    border-bottom-color: $cc-blue3;
    border-bottom-width: 2px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}
.cc-ClassListField .dropitem.drag-over-right > .ws-tile {
    background-color: $cc-lightblue3;
    border-right-color: $cc-blue3;
    border-right-width: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.cc-ClassListField.ws-tiles .ws-tile {
    padding: 4px;
    border-radius: 6px;
    background-color: $cc-lightgray5;
}
.cc-ClassListField.ws-tiles .ws-tile .ws-text {
    text-align: left;
}
.cc-ClassField > .ws-propertytable,
.cc-ClassListField > .dropitem > .ws-row > .ws-propertytable {
    padding: 4px 4px 0;
    border: 1px solid $cc-lightgray3;
    border-radius: 6px;
}

.cc-ClassField .ws-propertytable-cell-input,
.cc-ClassListField .ws-propertytable-cell-input {
    padding-right: 0;
}

.cc-ClassListField.ws-table {
    margin: 0;
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
    overflow: hidden;
    background-color: $cc-white;
}
.cc-ClassListField.ws-table .ws-table {
    margin: 0;
}
.cc-ClassListField.ws-table .ws-table-cell {
    border-color: $cc-lightgray5;
    text-align: left;
}
.cc-ClassListField.ws-table .ws-table-cell .cc-Input {
    background-color: transparent;
}
.cc-ClassListField.ws-table .ws-table-cell.ws-table-cell-_action_ {
    text-align: center;
}
.cc-ClassListField.ws-table tr .ws-table-cell:last-child {
    border-right: 0;
}
.cc-ClassListField.ws-table tbody tr:last-child .ws-table-cell {
    border-bottom: 0;
}
.cc-ClassListField .av-LayerView > .av-Layer.classlist-actions {
    line-height: 0;
    top: 4px;
    right: 6px;
}
.cc-ClassListField .ws-table-cell-_action_ {
    background-color: $cc-lightgray5;
    width: 21px;
}
.cc-ClassListField .ws-table-cell-_add-text_ {
    padding: 0 5px;
}
.cc-ClassListField thead .ws-table-cell {
    background-color: $cc-lightgray5;
}
.cc-ClassListField.ws-table thead .ws-table-cell {
    line-height: 16px;
    font-size: $cc-font-size2;
    font-weight: 500;
}

.cc-TextListField.ws-table {
    margin: 0;
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
    overflow: hidden;
    background-color: $cc-white;
}
.cc-TextListField.ws-table .ws-table {
    margin: 0;
}
.cc-TextListField.ws-table .ws-table-cell {
    border-color: $cc-lightgray5;
    text-align: left;
}
.cc-TextListField.ws-table .ws-table-cell .cc-Input {
    background-color: transparent;
    font-size: 14px;
}
.cc-TextListField.ws-table .ws-table-cell.ws-table-cell-_action_ {
    text-align: center;
}
.cc-TextListField.ws-table tr .ws-table-cell:last-child {
    border-right: 0;
}
.cc-TextListField.ws-table tbody tr:first-child .ws-table-cell {
    border-top: 0;
}
.cc-TextListField.ws-table tbody tr:last-child .ws-table-cell {
    border-bottom: 0;
}
.cc-TextListField .av-LayerView > .av-Layer.classlist-actions {
    line-height: 0;
    top: 4px;
    right: 6px;
}
.cc-TextListField .ws-table-cell-_action_ {
    background-color: $cc-lightgray5;
    width: 21px;
}
.ws-table-cell-_action_ .cc-Icon {
    position: relative;
    top: 1px;
}
.cc-TextListField .ws-table-cell-_add-text_ {
    padding: 0 5px;
}
.cc-TextListField thead .ws-table-cell {
    background-color: $cc-lightgray5;
}
.cc-TextListField.ws-table thead .ws-table-cell {
    line-height: 16px;
    font-size: $cc-font-size2;
    font-weight: 500;
}

.cc-TextListInput.ws-table {
    margin: 0;
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
    overflow: hidden;
    background-color: $cc-white;
}
.cc-TextListInput.ws-table .ws-table {
    margin: 0;
}
.cc-TextListInput.ws-table .ws-table-cell {
    border-color: $cc-lightgray5;
    text-align: left;
}
.cc-TextListInput.ws-table .ws-table-cell .cc-Input {
    background-color: transparent;
    font-size: 14px;
}
.cc-TextListInput.ws-table .ws-table-cell .cc-Input.validation-error {
    background-color: $cc-lightred5;
}
.cc-TextListInput.ws-table .ws-table-cell .cc-Input.validation-report {
    background-color: $cc-lightorange5;
}
.worksheet .ws-table .cc-Input.validation-error {
    border: 1px solid $cc-red3;
    border-radius: 3px;
}
.worksheet .ws-table .cc-Input.validation-report {
    border: 1px solid $cc-orange3;
    border-radius: 3px;
}

.cc-TextListInput.ws-table .ws-table-cell.ws-table-cell-_action_ {
    text-align: center;
}
.cc-TextListInput.ws-table tr .ws-table-cell:last-child {
    border-right: 0;
}
.cc-TextListInput.ws-table tbody tr:first-child .ws-table-cell {
    border-top: 0;
}
.cc-TextListInput.ws-table tbody tr:last-child .ws-table-cell {
    border-bottom: 0;
}
.cc-TextListInput .av-LayerView > .av-Layer.classlist-actions {
    line-height: 0;
    top: 4px;
    right: 6px;
}
.cc-TextListInput .ws-table-cell-_action_ {
    background-color: $cc-lightgray5;
    width: 21px;
}
.cc-TextListInput .ws-table-cell-_add-text_ {
    padding: 0 5px;
}
.cc-TextListInput thead .ws-table-cell {
    background-color: $cc-lightgray5;
}
.cc-TextListInput.ws-table thead .ws-table-cell {
    line-height: 16px;
    font-size: $cc-font-size2;
    font-weight: 500;
}

.cc-Backups.ws-table {
    margin: 0;
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
    overflow: hidden;
    background-color: $cc-white;
}
.cc-Backups.ws-table .ws-table {
    margin: 0;
}
.cc-Backups.ws-table .ws-table-cell {
    border-color: $cc-lightgray5;
    text-align: left;
    padding: 3px 5px;
}
.cc-Backups.ws-table .ws-table-cell.cc-backup-version {
    text-align: center;
}
.cc-Backups.ws-table .ws-table-cell .cc-Input {
    background-color: transparent;
}
.cc-Backups.ws-table tr .ws-table-cell:last-child {
    border-right: 0;
}
.cc-Backups.ws-table tbody tr:last-child .ws-table-cell {
    border-bottom: 0;
}
.cc-Backups thead .ws-table-cell {
    background-color: $cc-lightgray5;
}
.cc-Backups.ws-table thead .ws-table-cell {
    line-height: 16px;
    font-size: $cc-font-size2;
    font-weight: 500;
}

.worksheet .ws-table-cell .cc-FieldpickerField {
    border: 0;
}

.fieldcontextmenu-panel .cc-Property-Value .cc-Input {
    max-height: 85px;
}
.fieldcontextmenu-panel .cc-Property-Value .cc-Input-multiline {
    min-height: 34px;
    overflow: auto;
}
.fieldcontextmenu-panel .cc-Property-Value .cc-Input-multiline > div {
    min-height: 30px;
}
.cc-Field {
    min-width: 40px;
    width: 100%;
}
.cc-AssetField .cc-AssetItem {
    padding: $cc-pad1 $cc-pad2;
}
.cc-AssetField-thumb {
    line-height: 0;
}
.cc-BarcodeField .cc-FieldPreview {
    padding-top: $cc-pad2;
}
.cc-BooleanField {
    padding: $cc-pad1;
}
.ws-field .cc-BooleanField {
    padding: $cc-pad1 $cc-pad2;
}
.ws-field .cc-BooleanField .cc-Toggle-Knob {
    border: 1px solid $cc-lightgray2;
}
.ws-field .cc-BooleanField .cc-Toggle-Knob::before {
    top: 1px;
    left: 1px;
}

.ws-field .cc-Toggle {
    margin: 3px 0 0 5px;
}
.ws-field .cc-Toggle-Knob {
    border: 1px solid $cc-lightgray2;
}
.ws-field .cc-Toggle-Knob::before {
    top: 1px;
    left: 1px;
}

.cc-MenuItem .cc-Toggle-Knob {
    border: 1px solid $cc-lightgray2;
}
.cc-MenuItem .cc-Toggle-Knob::before {
    top: 1px;
    left: 1px;
}
.cc-MenuItem > .control {
    display: inline-block;
    position: relative;
    left: -4px;
    width: 21px;
    text-align: center;
    margin-right: 4px;
}
.cc-MenuItem > .control .cc-Icon {
    margin-right: 0;
}

.ws-propertytable-cell-input .cc-RecordField {
    max-width: 267px;
    overflow: hidden;
    overflow: auto;
}
.ws-propertytable-cell-input .cc-RecordListField {
    max-width: 267px;
    overflow: auto;
}
.ws-propertytable-cell-input .cc-RecordListField .ws-tile {
    background-color: white;
}
.ws-propertytable-cell-input .ws-tiles {
    grid-gap: 2px;
}
.ws-propertytable-cell-input .ws-tiles .ws-tile {
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
}
.cc-RecordField .cc-RecordItem {
    padding: $cc-pad1 $cc-pad2;
    margin-bottom: $cc-pad1;
}
.ws-propertytable-cell-unit {
    padding: 3px 0 2px 5px;
    width: 60px;
    max-width: 60px;
}

.layout-preview .ws-table-cell {
    white-space: nowrap;
}

.ws-table-cell-artnr {
    text-align: left;
}
.ws-table-cell-description {
    text-align: left;
}
.ws-table-cell-price {
    text-align: right;
}
.ws-record-navigator > .ws-tile {
    padding: $cc-size4;
}
.layout-preview .ws-record-navigator > .ws-tile {
    padding: $cc-size1;
}
.panel.properties > .cc-Input {
    margin: 0 5px;
}

.ws-helptext {
    padding: $cc-pad1 $cc-pad2;
    color: $cc-gray2;
    pointer-events: none;
    font-size: 10px;
}

.cc-toggle-label .cc-Property-Label {
    width: 40px;
}
.cc-toggle-label .cc-Property-Value {
    max-width: calc(100% - 40px);
}

.inspector .cc-Input,
.worksheet .cc-Input {
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
}
.inspector .cc-FieldpickerField,
.worksheet .cc-FieldpickerField {
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
    padding: 2px 0;
}
.inspector .cc-FieldpickerField {
    padding: 1px 0;
    background: $cc-white;
}
.worksheet .cc-ClassInput {
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
    padding: 2px 5px;
    background: $cc-white;
}

.worksheet .cc-ClassField {
    border: 1px solid $cc-lightgray3;
    border-radius: 6px;
    padding: 2px 5px 4px;
    background-color: $cc-lightgray5;
}

.worksheet:not(.login-worksheet, .signup-worksheet) .cc-Input.cc-disabled,
.worksheet:not(.login-worksheet, .signup-worksheet) .cc-Field.cc-disabled,
.worksheet:not(.login-worksheet, .signup-worksheet) .cc-Field.cc-disabled .ws-tile {
    color: $cc-text-color;
}
.worksheet .ws-table .cc-Input {
    border: 0;
    border-radius: 0;
}
.inspector .cc-Input.validation-error,
.worksheet .cc-Input.validation-error {
    border-color: $cc-red3;
}
.inspector .cc-Input.validation-report,
.worksheet .cc-Input.validation-report {
    border-color: $cc-orange3;
}

.inspector .cc-TextListInput,
.worksheet .cc-TextListInput {
    border: 0;
    border-radius: 0;
}
.inspector .cc-TextListField.validation-error,
.worksheet .cc-TextListField.validation-error {
    border: 1px solid $cc-red3;
    border-radius: 3px;
    padding: 2px;
}
.inspector .cc-TextListField.validation-report,
.worksheet .cc-TextListField.validation-report {
    border: 1px solid $cc-orange3;
    border-radius: 3px;
    padding: 2px;
}

.inspector .cc-TextListField.ws-field,
.worksheet .cc-TextListField.ws-field {
    padding-top: 16px;
}

.ws-field {
    border: 0;
}
.ws-field .ws-label .label {
    background-color: transparent;
    color: $cc-text-color;
    font-weight: 500;
}

.ws-field.ws-navigation-tile {
    padding-top: 0;
    min-height: 1.5em;
    margin-bottom: 0;
}
.ws-field.ws-navigation-tile .ws-label {
    display: none;
}
.ws-field.ws-navigation-tile .cc-Input,
.ws-field.ws-navigation-tile .cc-Field {
    border: 0;
}

.ws-selected.ws-field .ws-label,
.ws-selected.ws-field .ws-label .label {
    background-color: transparent;
}
.ws-field .ws-label .language {
    border: 0;
    margin-right: 2px;
    position: relative;
    top: -1px;
}
.ws-field .ws-label .cc-Icon {
    position: relative;
    top: 1px;
}
.ws-field .cc-RecordField,
.ws-field .cc-AssetField,
.ws-field .cc-Fieldsfield,
.ws-field .cc-Classesfield,
.ws-field .cc-Definitionsfield,
.ws-field .cc-Layoutfield,
.ws-field .cc-Select,
.ws-field .ws-tile {
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
}
.ws-field .cc-RecordField,
.ws-field .cc-AssetField,
.ws-field .cc-FieldpickerField,
.ws-field .ws-til,
.ws-field .ws-tile .ws-tile {
    background-color: $cc-white;
}

.ws-field .cc-RecordField:not(.ws-tiles).status-deleted,
.ws-field .cc-AssetField:not(.ws-tiles).status-deleted {
    color: $cc-red2;
    text-decoration: line-through $cc-red1;
    border: 1px solid $cc-red3;
    opacity: 0.4;
}

.worksheet .cc-Property {
    border: 0;
}
.worksheet .cc-Property-Label {
    background-color: transparent;
    color: $cc-text-color;
    font-weight: 500;
}
.worksheet .cc-Property-Value {
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
    padding-right: 0;
}
.login-worksheet .cc-Property-Value,
.signup-worksheet .cc-Property-Value,
.worksheet .cc-RecordCard .cc-Property-Value,
.worksheet .cc-AssetCard .cc-Property-Value,
.worksheet:not(.login-worksheet, .signup-worksheet) .cc-Property-Value .cc-Input {
    border: 0;
}
.ws-project-navigator .ws-tile {
    border-radius: 3px;
}
.worksheet .cc-Property.avatar-property .cc-Property-Value,
.worksheet .cc-Property.projecticon-property .cc-Property-Value {
    border: 0;
}

.select-field-panel .search-results,
.select-class-panel .search-results,
.select-definition-panel .search-results,
.recorddefinition-change-panel .search-results,
.recorddefinition-add-class-panel .search-results,
.recorddefinition-add-field-panel .search-results,
.recorddefinition-add-childdefinition-panel .search-results {
    height: 164px;
    min-height: 164px;
}
.select-field-panel .cc-Property-Label,
.select-class-panel .cc-Property-Label,
.select-definition-panel .cc-Property-Label,
.recorddefinition-add-class-panel .cc-Property-Label,
.recorddefinition-add-field-panel .cc-Property-Label {
    width: 40px;
}
.select-field-panel .cc-Property-Value,
.select-class-panel .cc-Property-Value,
.select-definition-panel .cc-Property-Value,
.recorddefinition-add-class-panel .cc-Property-Value,
.recorddefinition-add-field-panel .cc-Property-Value {
    max-width: calc(100% - 40px);
}
.select-field-panel .nobreak .cc-Property-Value,
.select-class-panel .nobreak .cc-Property-Value,
.select-definition-panel .nobreak .cc-Property-Value,
.recorddefinition-add-class-panel .nobreak .cc-Property-Value,
.recorddefinition-add-field-panel .nobreak .cc-Property-Value {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.project-add-language-panel .search-results {
    height: 164px;
    min-height: 164px;
}

.open-query-panel .search-results {
    height: 164px;
    min-height: 164px;
}

.selectedrecord-panel,
.selectedasset-panel,
.record-field-menu,
.asset-field-menu {
    width: 200px;
    overflow-x: hidden;
}

.cc-Popover .panel.asset-search-panel {
    padding: 5px 0;
    overflow-x: hidden;
}

.cc-Popover .panel.folder-search-panel {
    padding: 5px 0;
    overflow-x: hidden;
}

.cc-Popover .panel.record-search-panel {
    padding: 5px 0;
    overflow-x: hidden;
}

.validation-error {
    background-color: $cc-lightred5;
}
.validation-report {
    background-color: $cc-lightorange5;
}
.cc-ValidationMessage {
    padding: $cc-pad2 $cc-pad2 $cc-pad1;
    font-size: $cc-font-size2;
    font-weight: 400;
}
.cc-Input .cc-ValidationMessage {
    padding: $cc-pad2 0 0;
}
.cc-ValidationMessage.validation-error {
    color: $cc-red1;
}
.cc-ValidationMessage.validation-report {
    color: $cc-orange1;
}

.cc-layout-preview {
    margin-top: 16px;
}
.cc-layout-preview .field {
    margin: 2px;
    padding: 2px;
    border: 1px solid $cc-lightgray3;
}
.cc-layout-preview .lc-title {
    font-size: 16px;
    font-weight: 600;
}
.cc-layout-preview .lc-subtitle {
    font-size: 12px;
    font-weight: 600;
}

.workspace-schema .worksheet .cc-Header,
.workspace-dam .worksheet .cc-Header,
.workspace-settings .worksheet .cc-Header,
.workspace-account .worksheet .cc-Header {
    font-size: $cc-font-size4;
    font-weight: 600;
    padding-left: 0;
    margin-bottom: $cc-margin4;
}
.workspace-dam .worksheet .cc-Header {
    margin-bottom: $cc-margin2;
}

.workspace-settings .navigator .cc-Item {
    display: flex;
    align-items: center;
    padding: $cc-size3;
    column-gap: $cc-size3;
}
.workspace-settings .navigator .avatar {
    width: 32px;
    height: 32px;
}

.workspace-account .navigator .cc-Item {
    display: flex;
    align-items: center;
    padding: $cc-size3;
    column-gap: $cc-size3;
}
.workspace-account .navigator .avatar {
    width: 32px;
    height: 32px;
}

.lost-fields {
    max-height: 164px;
}

.uploaded-asset-menu .inspector {
    padding: 5px 10px;
}

.excel-import-panel .inspector {
    padding: 5px 15px 10px;
}
.excel-upload {
    border: 2px dashed $cc-lightgray3;
    margin-bottom: 5px;
    padding: 40px 20px;
    text-align: center;
    background: white;
}
.excel-upload.drag-over-inside {
    border: 2px solid $cc-blue3;
}
.excel-upload.excel-upload-not-excelfile {
    border-color: $cc-danger-border-color;
}
.excel-upload.excel-upload-not-excelfile .cc-Header {
    color: $cc-danger-text-color;
}
.excel-upload.excel-upload-processing {
    border-color: $cc-info-border-color;
}
.excel-upload.excel-upload-processing .cc-Header {
    color: $cc-info-text-color;
}
.excel-upload.excel-upload-result {
    border-style: solid;
}
.excel-upload.excel-upload-result.excel-upload-result-error {
    border-color: $cc-danger-border-color;
}
.excel-upload.excel-upload-result.excel-upload-result-warning {
    border-color: $cc-notice-border-color;
}
.excel-upload.excel-upload-result.excel-upload-result-ok {
    border-color: $cc-ok-border-color;
}
