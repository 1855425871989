@import 'config/sass/variables';

body {
    margin: 0;
    font-family: $cc-font-family;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $cc-font-size;
}

code {
    font-family: $cc-font-family-monospace;
}

input {
    cursor: auto;
    border: 0;
    font-size: 1em;
    width: 100%;
}

#root {
    width: 100vw;
    height: 100vh;
    min-width: 1200px;
    min-height: 400px;
    overflow: hidden;
    z-index: 1;
}

#portal {
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    min-width: 1200px;
    min-height: 400px;
    z-index: 2;
    position: absolute;
    pointer-events: none;
}

#portal > div {
    position: absolute;
    pointer-events: auto;
}

input.cc-FileUpload {
    top: -200px;
    left: -200px;
    width: 100px;
    height: 10px;
    max-width: 100px;
    max-height: 10px;
    position: fixed;
}
