@import '../../config/sass/variables';

.cc-grow {
    height: 100%;
    min-height: 100%;
    width: 100%;
    min-width: 100%;
}
.cc-noline {
    line-height: 0;
}

.cc-multiselected {
    background-color: $cc-multiselection-background-color;
    color: $cc-multiselection-text-color;
}
.cc-selected {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}
.maybe-start-dragging .cc-selected {
    background-color: transparent;
    color: $cc-text-color;
}
.cc-dimmed {
    color: $cc-text-color-dimmed;
}
.cc-unnamed-layout-component {
    color: $cc-darkgray4;
}
.cc-hidden {
    display: none;
}
.cc-note {
    font-size: $cc-font-size2;
}
.cc-checkered {
    background: -webkit-linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.0980392) 25%,
            transparent 25%,
            transparent 75%,
            rgba(0, 0, 0, 0.0980392) 75%,
            rgba(0, 0, 0, 0.0980392) 0
        ),
        -webkit-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent
                    75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0),
        white;
    background: -moz-linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.0980392) 25%,
            transparent 25%,
            transparent 75%,
            rgba(0, 0, 0, 0.0980392) 75%,
            rgba(0, 0, 0, 0.0980392) 0
        ),
        -moz-linear-gradient(45deg, rgba(0, 0, 0, 0.0980392) 25%, transparent 25%, transparent
                    75%, rgba(0, 0, 0, 0.0980392) 75%, rgba(0, 0, 0, 0.0980392) 0),
        white;
    background: linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.0980392) 25%,
            transparent 25%,
            transparent 75%,
            rgba(0, 0, 0, 0.0980392) 75%,
            rgba(0, 0, 0, 0.0980392) 0
        ),
        linear-gradient(
            45deg,
            rgba(0, 0, 0, 0.0980392) 25%,
            transparent 25%,
            transparent 75%,
            rgba(0, 0, 0, 0.0980392) 75%,
            rgba(0, 0, 0, 0.0980392) 0
        ),
        white;
    background-repeat: repeat, repeat;
    background-position: 0px 0, 5px 5px;
    -webkit-transform-origin: 0 0 0;
    transform-origin: 0 0 0;
    -webkit-background-origin: padding-box, padding-box;
    background-origin: padding-box, padding-box;
    -webkit-background-clip: border-box, border-box;
    background-clip: border-box, border-box;
    -webkit-background-size: 10px 10px, 10px 10px;
    background-size: 10px 10px, 10px 10px;
    -webkit-box-shadow: none;
    box-shadow: none;
    text-shadow: none;
    -webkit-transition: none;
    -moz-transition: none;
    -o-transition: none;
    transition: none;
    -webkit-transform: scaleX(1) scaleY(1) scaleZ(1);
    transform: scaleX(1) scaleY(1) scaleZ(1);
}

.cc-Bar {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: stretch;
    overflow: hidden;
}
.cc-Bar.cc-raised {
    background-color: $cc-white;
    border: 1px solid $cc-lightgray3;
    border-radius: $cc-border-radius;
}
.cc-Bar.cc-vertical {
    flex-flow: column nowrap;
}

.cc-Button {
    position: relative;
    padding: $cc-pad2;
}
.cc-Button.cc-with-icon {
    line-height: 0.1em;
}
.cc-Button.cc-with-icon.cc-with-text {
    line-height: 1em;
}
.cc-Button.cc-disabled {
    color: $cc-text-color-disabled;
}
.cc-Button.cc-disabled path {
    stroke: $cc-text-color-disabled;
}
.cc-Button.cc-disabled path.cc-fill {
    fill: $cc-text-color-disabled;
}
.cc-Button.cc-active:not(.cc-disabled) {
    color: $cc-blue1;
}
.cc-Button.cc-active:not(.cc-disabled) path {
    stroke: $cc-blue1;
}
.cc-Button.cc-active:not(.cc-disabled) path.cc-fill {
    fill: $cc-text-color-active;
}

.cc-Button {
    background: $cc-white;
}
.cc-Button:not(.cc-disabled):hover {
    background: $cc-lightblue5;
}
.cc-Button.cc-mousedown:not(.cc-disabled) {
    background: $cc-lightgray4;
}

.cc-disabled {
    color: $cc-text-color-disabled;
}
.cc-disabled path {
    stroke: $cc-text-color-disabled;
}
.cc-disabled path.cc-fill {
    fill: $cc-text-color-disabled;
}
.cc-active:not(.cc-disabled) {
    color: $cc-blue1;
}
.cc-active:not(.cc-disabled) path {
    stroke: $cc-blue1;
}
.cc-active:not(.cc-disabled) path.cc-fill {
    fill: $cc-text-color-active;
}

.cc-Bar .cc-Button {
    width: 100%;
    background: transparent;
}
.cc-Bar.cc-raised .cc-Button.cc-action {
    text-align: center;
    border-bottom: 1px solid $cc-lightgray3;
}
.cc-Bar.cc-raised .cc-Button.cc-action.cc-disabled {
    background: $cc-lightgray5;
}
.cc-Bar.cc-raised .cc-Button.cc-action.cc-mousedown {
    border-bottom: 0;
}

.cc-Bar .cc-Button:not(.cc-disabled):hover {
    background: $cc-lightblue2;
}

.cc-Bar .cc-Button.cc-with-icon.cc-with-text > .cc-Icon {
    margin-right: 10px;
}

.cc-Footer {
    padding: $cc-pad2;
}

.cc-Header {
    padding: $cc-pad2;
    font-weight: 600;
    position: relative;
}

.cc-Header.cc-danger {
    color: $cc-red1;
}

.cc-Icon {
    width: $cc-icon-size;
    height: $cc-icon-size;
}
.cc-Icon.cc-icon-size1 {
    width: $cc-icon-size1;
    height: $cc-icon-size1;
}
.cc-Icon.cc-icon-size2 {
    width: $cc-icon-size2;
    height: $cc-icon-size2;
}
.cc-Icon.cc-icon-size3 {
    width: $cc-icon-size3;
    height: $cc-icon-size3;
}
.cc-Icon.cc-icon-size4 {
    width: $cc-icon-size4;
    height: $cc-icon-size4;
}
.cc-Icon.cc-icon-size5 {
    width: $cc-icon-size5;
    height: $cc-icon-size5;
}
.cc-Icon.cc-icon-size80 {
    width: 80px;
    height: 80px;
}
.cc-Icon.cc-icon-sizetext {
    width: $cc-icon-sizetext;
    height: $cc-icon-sizetext;
}
.cc-Icon.cc-icon-sizefill {
    width: 100%;
    height: 100%;
}
.cc-Icon path {
    width: $cc-icon-size;
    height: $cc-icon-size;
    fill: transparent;
    fill-rule: evenodd;
    stroke: $cc-text-color-muted;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
}
.cc-Icon path.cc-thin {
    stroke-width: 0.5;
}
.cc-Icon path.cc-hair {
    stroke-width: 0.5;
}
.cc-Icon path.cc-fill {
    fill: $cc-gray2;
}
.cc-Icon.cc-icon-sizetext path {
    stroke-width: 1;
}
.cc-Icon.cc-icon-sizetext path.cc-thin {
    stroke-width: 0.5;
}
.cc-Icon.cc-icon-sizetext path.cc-hair {
    stroke-width: 0.3;
}
.cc-Icon.cc-icon-size1 path {
    stroke-width: 1;
}
.cc-Icon.cc-icon-size1 path.cc-thin {
    stroke-width: 0.5;
}
.cc-Icon.cc-icon-size1 path.cc-hair {
    stroke-width: 0.5;
}
.cc-Icon.cc-icon-size80 path {
    stroke-width: 3;
}
.cc-Icon.cc-icon-size80 path.cc-thin {
    stroke-width: 1;
}
.cc-Icon.cc-icon-size80 path.cc-hair {
    stroke-width: 0.5;
}
.cc-dimmed .cc-Icon path {
    stroke: $cc-gray5;
}
.cc-dimmed .cc-Icon path.cc-fill {
    fill: $cc-lightgray2;
}

.cc-Icon g.cc-grid {
    display: none;
}
.cc-Icon g.cc-grid path {
    stroke: $cc-blue4;
}
.cc-Icon.cc-icon-size80 g.cc-grid {
    display: block;
}

@media (max-resolution: 120dpi) {
    .cc-Icon .cc-hi {
        display: none;
    }
}
@media (min-resolution: 121dpi) {
    .cc-Icon .cc-lo {
        display: none;
    }
}
.cc-Icon.cc-icon-sizetext .cc-min-1,
.cc-Icon.cc-icon-sizetext .cc-min-2,
.cc-Icon.cc-icon-size1 .cc-min-2,
.cc-Icon.cc-icon-sizetext .cc-min-3,
.cc-Icon.cc-icon-size1 .cc-min-3,
.cc-Icon.cc-icon-size2 .cc-min-3,
.cc-Icon.cc-icon-sizetext .cc-min-4,
.cc-Icon.cc-icon-size1 .cc-min-4,
.cc-Icon.cc-icon-size2 .cc-min-4,
.cc-Icon.cc-icon-size3 .cc-min-4,
.cc-Icon.cc-icon-sizetext .cc-min-5,
.cc-Icon.cc-icon-size1 .cc-min-5,
.cc-Icon.cc-icon-size2 .cc-min-5,
.cc-Icon.cc-icon-size3 .cc-min-5,
.cc-Icon.cc-icon-size4 .cc-min-5 {
    display: none;
}

.cc-Placeholder {
    padding: $cc-pad1 $cc-pad2;
    color: $cc-gray1;
    pointer-events: none;
}
.cc-Placeholder-inline {
    color: $cc-gray1;
}

.cc-Input {
    padding: $cc-pad1 $cc-pad2;
    background: $cc-white;
    position: relative;
}
.ws-text.ws-code {
    font-family: $cc-font-family-monospace;
    white-space: pre-wrap;
}
.cc-Input [contenteditable='true'],
.cc-Input input {
    min-height: 1em;
    min-width: 5em;
}
.cc-Input [contenteditable='true'] {
    white-space: pre-wrap;
}
.cc-Input .cc-Placeholder {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.cc-Input .cc-Placeholder.cc-AddNewItemPlaceholder {
    display: block;
    top: auto;
    bottom: 0;
    font-size: $cc-font-size;
    line-height: 18px;
}
.cc-Input .cc-Placeholder.cc-AddNewItemPlaceholder svg {
    position: relative;
    top: 4px;
    line-height: 18px;
}

.cc-Textfield .cc-Input [contenteditable='true'] {
    line-height: 1.4;
}
.av-LazyVScrollTable .cc-Textfield .cc-Input [contenteditable='true'] {
    line-height: normal;
}

.cc-Input.cc-placeholder-visible .cc-Placeholder {
    display: block;
}

.cc-Input.cc-disabled .cc-Placeholder,
.cc-Input.cc-disabled .cc-Placeholder-inline {
    color: $cc-gray5;
}

.cc-Popover .cc-Input-Options {
    padding: $cc-pad2 0;
}
.cc-Popover .cc-Input-Option {
    padding: $cc-pad1 $cc-pad2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cc-Popover .cc-Input-Option.cc-active {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}
.cc-Popover .cc-Input-Option:hover {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}

.cc-TextListInput {
    padding: 0;
    background: transparent;
}
.cc-TextListInput .cc-Input {
    padding-right: 20px;
}

.cc-PasswordInput {
    overflow: hidden;
}
.cc-PasswordInput input {
    padding: 0 17px 0 0;
    background: transparent;
}
.cc-PasswordInput input.cc-disabled {
    color: $cc-gray1;
}
.cc-PasswordInput .cc-Icon {
    position: absolute;
    top: 4px;
    right: 6px;
    opacity: 0.5;
}

.cc-SearchInput {
    position: relative;
    background-color: $cc-white;
    padding: 0;
    border-radius: $cc-pad2;
    margin-bottom: $cc-pad1;
    overflow: hidden;
    border: 1px solid $cc-lightgray3;
}
.cc-SearchInput .cc-Input {
    padding: 0;
    font-size: 14px;
}
.cc-SearchInput .cc-Input > div {
    padding: $cc-pad2 $cc-pad2 $cc-pad2 22px;
}
.cc-SearchInput .cc-Icon {
    position: absolute;
    top: 8px;
    left: 6px;
}

.cc-FolderInput {
    background: $cc-white;
}

.cc-Item {
    padding: $cc-pad1 $cc-pad2;
}
.cc-Item.cc-indent0 {
    padding-left: $cc-pad2;
}
.cc-Item.cc-indent1 {
    padding-left: $cc-pad2 * 2;
}
.cc-Item.cc-indent2 {
    padding-left: $cc-pad2 * 3;
}
.cc-Item.cc-indent3 {
    padding-left: $cc-pad2 * 4;
}
.cc-Item.cc-indent4 {
    padding-left: $cc-pad2 * 5;
}
.cc-Item.cc-indent5 {
    padding-left: $cc-pad2 * 6;
}
.cc-Item.cc-indent6 {
    padding-left: $cc-pad2 * 7;
}
.cc-Item.cc-indent7 {
    padding-left: $cc-pad2 * 8;
}
.cc-Item.cc-indent8 {
    padding-left: $cc-pad2 * 9;
}
.cc-Item.cc-indent9 {
    padding-left: $cc-pad2 * 10;
}
.cc-Item.cc-indent10 {
    padding-left: $cc-pad2 * 11;
}
.cc-Item.cc-indent11 {
    padding-left: $cc-pad2 * 12;
}
.cc-Item.cc-indent12 {
    padding-left: $cc-pad2 * 13;
}
.cc-Item.cc-indent13 {
    padding-left: $cc-pad2 * 14;
}
.cc-Item.cc-indent14 {
    padding-left: $cc-pad2 * 15;
}
.cc-Item.cc-indent15 {
    padding-left: $cc-pad2 * 16;
}
.cc-Item.cc-indent16 {
    padding-left: $cc-pad2 * 17;
}
.cc-Item.cc-indent17 {
    padding-left: $cc-pad2 * 18;
}
.cc-Item.cc-indent18 {
    padding-left: $cc-pad2 * 19;
}
.cc-Item.cc-indent19 {
    padding-left: $cc-pad2 * 20;
}

.cc-Label {
    display: inline-block;
    padding: $cc-pad1 $cc-pad2;
}

.cc-Property {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: $cc-margin2;
    position: relative;
}
.cc-Property-Label {
    padding: $cc-pad1 $cc-pad2;
    width: 80px;
    text-align: right;
    flex: 0 0 auto;
}
.cc-Property-Value {
    padding-right: $cc-pad2;
    flex: 1 1 auto;
    max-width: calc(100% - 80px);
}
.cc-Property-Value .cc-Text {
    padding: $cc-pad1 0 $cc-pad1 $cc-pad2;
}

.cc-Select {
    padding: 0;
    background: $cc-white;
    padding: $cc-pad1 $cc-pad2;
    padding-right: $cc-pad2 * 2 + 12;
    position: relative;
    min-width: 100px;
    min-height: 19px;
    border: 1px solid $cc-lightgray3;
    border-radius: 3px;
}
.cc-Select .cc-Select-Value {
    overflow: hidden;
    text-overflow: ellipsis;
}
.cc-Select .cc-Select-Value .cc-Placeholder {
    padding: 0;
}
.cc-Select .cc-Select-Icon {
    position: absolute;
    top: 2px;
    right: 5px;
}

.cc-Popover .cc-Select-Options {
    padding: $cc-pad2 0;
}
.cc-Select-Option {
    padding: $cc-pad1 $cc-pad2;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cc-Select-Option.cc-active {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}
.cc-Select-Option:hover {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}

.cc-Text {
    padding: $cc-pad2;
}
.cc-Text > .cc-Icon {
    position: relative;
    top: 1px;
}

.cc-Toggle {
    position: relative;
    display: inline-block;
    width: 21px;
    height: 17px;
}
.cc-Toggle > input {
    opacity: 0;
    width: 0;
    height: 0;
}
.cc-Toggle-Knob {
    background-color: $cc-white;
    position: absolute;
    top: 2px;
    left: 0;
    right: 0;
    bottom: 2px;
    border-radius: 13px;
}
.cc-Toggle-Knob::before {
    background-color: $cc-lightgray3;
    transition: 0.2s;
    content: ' ';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 9px;
    height: 9px;
    border-radius: 9px;
}
.cc-Toggle > input:checked + .cc-Toggle-Knob::before {
    background-color: $cc-text-color-muted;
    transform: translateX(8px);
}

.cc-Tree {
    padding: $cc-pad2 0;
}

.cc-TreeItem {
    padding: $cc-pad1 $cc-pad2 $cc-pad1 ($cc-pad3 + $cc-icon-sizetext + $cc-pad1);
    text-indent: -($cc-icon-sizetext + $cc-pad1);
    position: relative;
    white-space: nowrap;
    z-index: 1;
}
.cc-TreeItem * {
    text-indent: 0;
}
.cc-TreeItem .cc-TreeItem-Icon {
    margin-right: $cc-pad1;
}
.cc-TreeItem .cc-Icon.itemtype,
.cc-Item .cc-Icon.itemtype {
    position: relative;
    top: 2px;
    margin-right: 3px;
}

.cc-TreeItem.cc-indent0 {
    padding-left: $cc-pad3 * 0 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent1 {
    padding-left: $cc-pad3 * 1 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent2 {
    padding-left: $cc-pad3 * 2 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent3 {
    padding-left: $cc-pad3 * 3 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent4 {
    padding-left: $cc-pad3 * 4 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent5 {
    padding-left: $cc-pad3 * 5 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent6 {
    padding-left: $cc-pad3 * 6 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent7 {
    padding-left: $cc-pad3 * 7 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent8 {
    padding-left: $cc-pad3 * 8 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent9 {
    padding-left: $cc-pad3 * 9 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent10 {
    padding-left: $cc-pad3 * 10 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent11 {
    padding-left: $cc-pad3 * 11 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent12 {
    padding-left: $cc-pad3 * 12 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent13 {
    padding-left: $cc-pad3 * 13 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent14 {
    padding-left: $cc-pad3 * 14 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent15 {
    padding-left: $cc-pad3 * 15 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent16 {
    padding-left: $cc-pad3 * 16 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent17 {
    padding-left: $cc-pad3 * 17 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent18 {
    padding-left: $cc-pad3 * 18 + $cc-icon-sizetext + $cc-pad1;
}
.cc-TreeItem.cc-indent19 {
    padding-left: $cc-pad3 * 19 + $cc-icon-sizetext + $cc-pad1;
}

.cc-Tree .cc-TreeItem.cc-indent0 {
    padding-left: $cc-pad3 * 0 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent1 {
    padding-left: $cc-pad3 * 1 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent2 {
    padding-left: $cc-pad3 * 2 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent3 {
    padding-left: $cc-pad3 * 3 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent4 {
    padding-left: $cc-pad3 * 4 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent5 {
    padding-left: $cc-pad3 * 5 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent6 {
    padding-left: $cc-pad3 * 6 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent7 {
    padding-left: $cc-pad3 * 7 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent8 {
    padding-left: $cc-pad3 * 8 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent9 {
    padding-left: $cc-pad3 * 9 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent10 {
    padding-left: $cc-pad3 * 10 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent11 {
    padding-left: $cc-pad3 * 11 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent12 {
    padding-left: $cc-pad3 * 12 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent13 {
    padding-left: $cc-pad3 * 13 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent14 {
    padding-left: $cc-pad3 * 14 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent15 {
    padding-left: $cc-pad3 * 15 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent16 {
    padding-left: $cc-pad3 * 16 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent17 {
    padding-left: $cc-pad3 * 17 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent18 {
    padding-left: $cc-pad3 * 18 + $cc-icon-sizetext + $cc-pad3;
}
.cc-Tree .cc-TreeItem.cc-indent19 {
    padding-left: $cc-pad3 * 19 + $cc-icon-sizetext + $cc-pad3;
}

.drag-over-inside .cc-TreeItem,
.drag-over-top .cc-TreeItem,
.drag-over-bottom .cc-TreeItem {
    background-color: $cc-lightblue3;
}
.drag-over-inside .cc-TreeItem::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $cc-blue4;
    z-index: -1;
}
.drag-over-top .cc-TreeItem::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 2px;
    background-color: $cc-blue3;
}
.drag-over-bottom .cc-TreeItem::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    background-color: $cc-blue3;
}
.drag-over-inside .cc-TreeItem.cc-indent0::before,
.drag-over-top .cc-TreeItem.cc-indent0::before,
.drag-over-bottom .cc-TreeItem.cc-indent0::before {
    left: $cc-pad3;
}
.drag-over-inside .cc-TreeItem.cc-indent1::before,
.drag-over-top .cc-TreeItem.cc-indent1::before,
.drag-over-bottom .cc-TreeItem.cc-indent1::before {
    left: $cc-pad3 * 2;
}
.drag-over-inside .cc-TreeItem.cc-indent2::before,
.drag-over-top .cc-TreeItem.cc-indent2::before,
.drag-over-bottom .cc-TreeItem.cc-indent2::before {
    left: $cc-pad3 * 3;
}
.drag-over-inside .cc-TreeItem.cc-indent3::before,
.drag-over-top .cc-TreeItem.cc-indent3::before,
.drag-over-bottom .cc-TreeItem.cc-indent3::before {
    left: $cc-pad3 * 4;
}
.drag-over-inside .cc-TreeItem.cc-indent4::before,
.drag-over-top .cc-TreeItem.cc-indent4::before,
.drag-over-bottom .cc-TreeItem.cc-indent4::before {
    left: $cc-pad3 * 5;
}
.drag-over-inside .cc-TreeItem.cc-indent5::before,
.drag-over-top .cc-TreeItem.cc-indent5::before,
.drag-over-bottom .cc-TreeItem.cc-indent5::before {
    left: $cc-pad3 * 6;
}
.drag-over-inside .cc-TreeItem.cc-indent6::before,
.drag-over-top .cc-TreeItem.cc-indent6::before,
.drag-over-bottom .cc-TreeItem.cc-indent6::before {
    left: $cc-pad3 * 7;
}
.drag-over-inside .cc-TreeItem.cc-indent7::before,
.drag-over-top .cc-TreeItem.cc-indent7::before,
.drag-over-bottom .cc-TreeItem.cc-indent7::before {
    left: $cc-pad3 * 8;
}
.drag-over-inside .cc-TreeItem.cc-indent8::before,
.drag-over-top .cc-TreeItem.cc-indent8::before,
.drag-over-bottom .cc-TreeItem.cc-indent8::before {
    left: $cc-pad3 * 9;
}
.drag-over-inside .cc-TreeItem.cc-indent9::before,
.drag-over-top .cc-TreeItem.cc-indent9::before,
.drag-over-bottom .cc-TreeItem.cc-indent9::before {
    left: $cc-pad3 * 10;
}
.drag-over-inside .cc-TreeItem.cc-indent10::before,
.drag-over-top .cc-TreeItem.cc-indent10::before,
.drag-over-bottom .cc-TreeItem.cc-indent10::before {
    left: $cc-pad3 * 11;
}
.drag-over-inside .cc-TreeItem.cc-indent11::before,
.drag-over-top .cc-TreeItem.cc-indent11::before,
.drag-over-bottom .cc-TreeItem.cc-indent11::before {
    left: $cc-pad3 * 12;
}
.drag-over-inside .cc-TreeItem.cc-indent12::before,
.drag-over-top .cc-TreeItem.cc-indent12::before,
.drag-over-bottom .cc-TreeItem.cc-indent12::before {
    left: $cc-pad3 * 13;
}
.drag-over-inside .cc-TreeItem.cc-indent13::before,
.drag-over-top .cc-TreeItem.cc-indent13::before,
.drag-over-bottom .cc-TreeItem.cc-indent13::before {
    left: $cc-pad3 * 14;
}
.drag-over-inside .cc-TreeItem.cc-indent14::before,
.drag-over-top .cc-TreeItem.cc-indent14::before,
.drag-over-bottom .cc-TreeItem.cc-indent14::before {
    left: $cc-pad3 * 15;
}
.drag-over-inside .cc-TreeItem.cc-indent15::before,
.drag-over-top .cc-TreeItem.cc-indent15::before,
.drag-over-bottom .cc-TreeItem.cc-indent15::before {
    left: $cc-pad3 * 16;
}
.drag-over-inside .cc-TreeItem.cc-indent16::before,
.drag-over-top .cc-TreeItem.cc-indent16::before,
.drag-over-bottom .cc-TreeItem.cc-indent16::before {
    left: $cc-pad3 * 17;
}
.drag-over-inside .cc-TreeItem.cc-indent17::before,
.drag-over-top .cc-TreeItem.cc-indent17::before,
.drag-over-bottom .cc-TreeItem.cc-indent17::before {
    left: $cc-pad3 * 18;
}
.drag-over-inside .cc-TreeItem.cc-indent18::before,
.drag-over-top .cc-TreeItem.cc-indent18::before,
.drag-over-bottom .cc-TreeItem.cc-indent18::before {
    left: $cc-pad3 * 19;
}
.drag-over-inside .cc-TreeItem.cc-indent19::before,
.drag-over-top .cc-TreeItem.cc-indent19::before,
.drag-over-bottom .cc-TreeItem.cc-indent19::before {
    left: $cc-pad3 * 20;
}

.cc-MenuHeader .cc-Header {
    padding: $cc-pad1 $cc-pad2 $cc-pad2 $cc-pad2;
}

.cc-MenuItem {
    padding: $cc-pad1 $cc-pad5 $cc-pad1 $cc-pad4;
    position: relative;
}
.cc-MenuItem .cc-MenuItem-Indicator {
    position: absolute;
    right: 5px;
    top: 3px;
}

.cc-MenuItem.cc-selected {
    background-color: inherit;
    color: $cc-text-color;
}
.cc-MenuItem.cc-selected.cc-danger {
    background-color: inherit;
    color: $cc-text-color;
}
.cc-MenuItem.cc-selected.cc-notice {
    background-color: inherit;
    color: $cc-text-color;
}
.cc-MenuItem.cc-selected.has-submenu,
.cc-MenuItem.cc-selected:hover {
    background-color: $cc-selection-background-color;
    color: $cc-selection-text-color;
}
.cc-MenuItem.cc-selected.cc-danger.has-submenu,
.cc-MenuItem.cc-selected.cc-danger:hover {
    background-color: $cc-danger-background-color;
    color: $cc-danger-text-color;
}
.cc-MenuItem.cc-selected.cc-notice.has-submenu,
.cc-MenuItem.cc-selected.cc-notice:hover {
    background-color: $cc-notice-background-color;
    color: $cc-notice-text-color;
}

.cc-MenuItem.cc-divider {
    padding: 0;
    margin: $cc-pad2 0;
    line-height: 1px;
    height: 1px;
    background-color: $cc-divider;
}

.cc-MenuItem.cc-disabled {
    color: $cc-text-color-disabled;
}
.cc-MenuItem.cc-disabled path {
    stroke: $cc-text-color-disabled;
}
.cc-MenuItem.cc-disabled path.cc-fill {
    fill: $cc-text-color-disabled;
}

.cc-MenuItem .cc-Icon:not(.cc-MenuItem-Indicator) {
    margin-right: $cc-pad2;
    position: relative;
    top: 2px;
}

.cc-Popover {
    top: 0;
    left: 0;
    background-color: $cc-app-background-color;
    color: $cc-text-color;
    border: 1px solid $cc-divider;
    border-radius: $cc-border-radius;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.2);
}
.cc-Popover > div {
    border-radius: $cc-border-radius;
}
.cc-Select-Popover {
    border-radius: 3px;
}
.cc-Select-Popover > div {
    border-radius: 3px;
}

.cc-PopoverArrow {
    width: 10px;
    height: 10px;
    position: absolute;
    top: 0;
    left: 0;
    transform: rotate(0deg);
}
.cc-PopoverArrow path {
    fill: $cc-app-background-color;
    fill-rule: evenodd;
    stroke: $cc-divider;
    stroke-width: 1;
    stroke-linecap: round;
    stroke-linejoin: round;
}
/*
@media (prefers-color-scheme: dark) {
    .cc-Popover {
        background-color: $cc-app-background-color-dark;
        color: $cc-text-color-dark;
        border: 1px solid $cc-divider-dark;
    }
    .cc-PopoverArrow path {
        fill: $cc-app-background-color-dark;
        stroke: $cc-divider-dark;
    }
}
*/

.cc-Icon.cc-icon-spin {
    animation: spin 4000ms infinite linear;
}
.cc-Icon.cc-icon-spin-quickly {
    animation: spin 400ms infinite linear;
}
.cc-Icon.cc-icon-spin-reverse {
    animation-direction: reverse;
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.cc-DragFilterItem {
    max-width: 100%;
}
.cc-FilterItem {
    max-width: 100%;
    padding: $cc-pad2;
    position: relative;
    border: 1px solid $cc-divider;
    border-radius: 3px;
    background-color: $cc-white;
}
.cc-FilterItem.cc-indent0 {
    padding-left: $cc-pad4 + $cc-pad2;
}
.cc-FilterItem.cc-indent1 {
    padding-left: $cc-pad4 * 1 + $cc-pad2;
}

.cc-DropFilterItem {
    max-width: 100%;
    position: relative;
}
.cc-DropFilterItem.drag-over-inside,
.cc-DropFilterItem.drag-over-top,
.cc-DropFilterItem.drag-over-bottom {
    background-color: $cc-blue5;
}
.cc-DropFilterItem.drag-over-inside::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 2px;
    background-color: $cc-blue3;
}

.cc-Image-background {
    background-color: $cc-white;
}
