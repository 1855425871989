$cc-black: #000000;
$cc-white: #ffffff;

$cc-darkgray1: #1b1b28;
$cc-darkgray2: #30303c;
$cc-darkgray3: #44444f;
$cc-darkgray4: #595963;
$cc-darkgray5: #6e6e76;
$cc-gray1: #82828a;
$cc-gray2: #97979d;
$cc-gray3: #acacb1;
$cc-gray4: #b6b6ba;
$cc-gray5: #c0c0c4;
//$cc-lightgray1: #cbcbce;
$cc-lightgray1: #d5d5d8;
$cc-lightgray2: #dfdfe1;
$cc-lightgray3: #eaeaeb;
$cc-lightgray4: #f4f4f5;
$cc-lightgray5: #fafafb;

// https://mycolor.space/gradient?ori=to+right&hex=%23FFA500&hex2=%23FDE7BF&sub=1

$cc-red1: #b40e0e;
$cc-red2: #cd4942;
$cc-red3: #df7472;
$cc-red4: #eb9fa0;
$cc-red5: #f2c9cc;

$cc-lightred1: #f2d0d3;
$cc-lightred2: #f5d9dc;
$cc-lightred3: #f9e2e5;
$cc-lightred4: #fceced;
$cc-lightred5: #fff5f6;

$cc-green1: #10a228;
$cc-green2: #61b64f;
$cc-green3: #91ca75;
$cc-green4: #bbde9e;
$cc-green5: #e0f2c9;

$cc-lightgreen1: #e7f5d5;
$cc-lightgreen2: #ebf6dc;
$cc-lightgreen3: #eff7e2;
$cc-lightgreen4: #f2f9e9;
$cc-lightgreen5: #f6faf0;

$cc-blue1: #1874bf;
$cc-blue2: #448fcb;
$cc-blue3: #70aad8;
$cc-blue4: #9cc5e5;
$cc-blue5: #c9e0f2;

$cc-lightblue1: #d2e5f4;
$cc-lightblue2: #dbeaf6;
$cc-lightblue3: #e4eff8;
$cc-lightblue4: #edf4fa;
$cc-lightblue5: #f6f9fc;

$cc-orange1: #ffa500;
$cc-orange2: #ffb744;
$cc-orange3: #fec86e;
$cc-orange4: #fed897;
$cc-orange5: #fde7bf;

$cc-lightorange1: #fcebcc;
$cc-lightorange2: #fdefd5;
$cc-lightorange3: #fdf3de;
$cc-lightorange4: #fef6e7;
$cc-lightorange5: #fffaf0;

$cc-app-background-color: $cc-white;
$cc-app-background-color-dark: $cc-darkgray3;

$cc-text-color: $cc-darkgray1;
$cc-text-color-dark: $cc-lightgray5;
$cc-text-color-muted: $cc-darkgray4;
$cc-text-color-muted-dark: $cc-lightgray2;
$cc-text-color-dimmed: $cc-gray2;
$cc-text-color-dimmed-dark: $cc-gray4;
$cc-text-color-disabled: $cc-gray5;
$cc-text-color-disabled-dark: $cc-gray3;
$cc-text-color-active: $cc-blue3;
$cc-text-color-active-dark: $cc-blue2;

$cc-selection-background-color: $cc-blue5;
$cc-selection-text-color: $cc-text-color;
$cc-multiselection-background-color: $cc-lightblue2;
$cc-multiselection-text-color: $cc-selection-text-color;

$cc-danger-background-color: $cc-red5;
$cc-danger-border-color: $cc-red2;
$cc-danger-text-color: $cc-text-color;
$cc-notice-background-color: $cc-orange5;
$cc-notice-border-color: $cc-orange2;
$cc-notice-text-color: $cc-text-color;
$cc-ok-background-color: $cc-green5;
$cc-ok-border-color: $cc-green2;
$cc-ok-text-color: $cc-text-color;
$cc-info-background-color: $cc-blue5;
$cc-info-border-color: $cc-blue2;
$cc-info-text-color: $cc-text-color;

$cc-divider: $cc-lightgray3;
$cc-divider-dark: $cc-lightgray3;

$cc-size: 10px;
$cc-size1: floor($cc-size * 0.25);
$cc-size2: floor($cc-size * 0.5);
$cc-size3: $cc-size;
$cc-size4: $cc-size * 1.5;
$cc-size5: $cc-size * 2;

$cc-pad1: $cc-size1;
$cc-pad2: $cc-size2;
$cc-pad3: $cc-size3;
$cc-pad4: $cc-size4;
$cc-pad5: $cc-size5;

$cc-margin1: $cc-size1;
$cc-margin2: $cc-size2;
$cc-margin3: $cc-size3;
$cc-margin4: $cc-size4;
$cc-margin5: $cc-size5;

$cc-border-radius: $cc-size2;

$cc-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

$cc-font-family-monospace: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;

$cc-font-size: 12px;
$cc-font-size1: 8px;
$cc-font-size2: 10px;
$cc-font-size3: $cc-font-size;
$cc-font-size4: 16px;
$cc-font-size5: 20px;

$cc-line-height: 1.6rem;

// multiples of the sizes add up to 120
$cc-icon-size: 28px;
$cc-icon-sizetext: $cc-font-size;
$cc-icon-size1: 18px;
$cc-icon-size2: 24px;
$cc-icon-size3: $cc-icon-size;
$cc-icon-size4: 32px;
$cc-icon-size5: 44px;

:export {
    darkgray1: $cc-darkgray1;
    darkgray2: $cc-darkgray2;
    darkgray3: $cc-darkgray3;
    darkgray4: $cc-darkgray4;
    darkgray5: $cc-darkgray5;
    gray1: $cc-gray1;
    gray2: $cc-gray2;
    gray3: $cc-gray3;
    gray4: $cc-gray4;
    gray5: $cc-gray5;
    lightgray1: $cc-lightgray1;
    lightgray2: $cc-lightgray2;
    lightgray3: $cc-lightgray3;
    lightgray4: $cc-lightgray4;
    lightgray5: $cc-lightgray5;
    red1: $cc-red1;
    red2: $cc-red2;
    red3: $cc-red3;
    red4: $cc-red4;
    red5: $cc-red5;
    lightred1: $cc-lightred1;
    lightred2: $cc-lightred2;
    lightred3: $cc-lightred3;
    lightred4: $cc-lightred4;
    lightred5: $cc-lightred5;
    green1: $cc-green1;
    green2: $cc-green2;
    green3: $cc-green3;
    green4: $cc-green4;
    green5: $cc-green5;
    lightgreen1: $cc-lightgreen1;
    lightgreen2: $cc-lightgreen2;
    lightgreen3: $cc-lightgreen3;
    lightgreen4: $cc-lightgreen4;
    lightgreen5: $cc-lightgreen5;
    blue1: $cc-blue1;
    blue2: $cc-blue2;
    blue3: $cc-blue3;
    blue4: $cc-blue4;
    blue5: $cc-blue5;
    lightblue1: $cc-lightblue1;
    lightblue2: $cc-lightblue2;
    lightblue3: $cc-lightblue3;
    lightblue4: $cc-lightblue4;
    lightblue5: $cc-lightblue5;
    orange1: $cc-orange1;
    orange2: $cc-orange2;
    orange3: $cc-orange3;
    orange4: $cc-orange4;
    orange5: $cc-orange5;
    lightorange1: $cc-lightorange1;
    lightorange2: $cc-lightorange2;
    lightorange3: $cc-lightorange3;
    lightorange4: $cc-lightorange4;
    lightorange5: $cc-lightorange5;
}
